export const statuses = {
    IN_PROCESS: 'В процессе',
    SEND_TO_APPROVE: 'Отправлена на согласование',
    SEND_TO_REPROCESS: 'Отправлена на доработку',
    RECONCILED: 'Согласована, отправлена на утверждение',
    APPROVED: 'Утверждена'
};

export const IN_PROCESS = 'IN_PROCESS';
export const SEND_TO_APPROVE = 'SEND_TO_APPROVE';
export const SEND_TO_REPROCESS = 'SEND_TO_REPROCESS';
export const RECONCILED = 'RECONCILED';
export const APPROVED = 'APPROVED';
export const RECONCILED_BY_DEPARTMENTS = 'RECONCILED_BY_DEPARTMENTS';
export const APPROVED_BY_DEPARTMENTS = 'APPROVED_BY_DEPARTMENTS';
export const APPROVED_BY_ADDITIONAL_DEPARTMENTS = 'APPROVED_BY_ADDITIONAL_DEPARTMENTS';

export const ROLE_VILLAGE = 'ROLE_SNP_VILLAGE';
export const ROLE_DISTRICT = 'ROLE_SNP_DISTRICT';
export const ROLE_REGION = 'ROLE_SNP_REGION';
export const ROLE_DISTRICT_DEPARTMENTS = 'usersnp_district_departments';
export const ROLE_REGION_DEPARTMENTS = 'usersnp_region_departments';
