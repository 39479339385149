<template>
  <div>
    <div class="actions-tab in-tab snp-actions">
      <b-dropdown
        variant="link"
        class="info"
        right
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <div class="btn-download">
            <div>
              <span class="left">
                <i class="icon icon-info"></i>
              </span>
              <i class="icon icon-keyboard"></i>
            </div>
          </div>
        </template>
        <b-dropdown-text>
          <div
            v-if="isVisibleForHostName"
            class="info-button"
            @click="downloadStaticFile"
          >
            <span>
              СВОД 2022-I полугодие
              <i class="icon icon-folder"></i>
            </span>
          </div>

          <div class="info-title">
            <span>Паспорт СНП</span>
          </div>
          <div class="info-text">
            <p>
              Данный модуль предназначен для сбора и анализа данных по формам
              мониторинга СНП по приказу Первого заместителя Премьер-Министра
              Республики Казахстан - Министра регионального развития Республики
              Казахстан от 24 сентября 2013 года № 239/ОД.
            </p>
          </div>
        </b-dropdown-text>
      </b-dropdown>
      <b-dropdown
        variant="link"
        right
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <div class="btn-download">
            <div @click="downloadFile">
              <i
                class="icon icon-download"
                style="color: #01ac50; margin-left: 9px"
              ></i>
              <span style="margin-right: 15px">Скачать</span>
            </div>
            <i class="icon icon-keyboard"></i>
          </div>
        </template>
        <b-dropdown-item @click="downloadAll">Все формы</b-dropdown-item>
        <b-dropdown-item @click="downloadFile">Текущая форма</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="filter-container" ref="filterContainer">
      <div class="left-content">
        <b-dropdown class="filter-dropdown" variant="default" ref="drop">
          <template #button-content>
            <span class="lc"> <i class="icon icon-filter" /> Фильтр </span>
            <span class="rc">
              <i class="icon icon-keyboard" />
            </span>
          </template>
          <div>
            <div class="top-content">
              <span>Параметры фильтра</span>
              <i class="icon icon-close" @click="hideFiltersPanel" />
            </div>

            <div class="filter-block">
              <b-form-group :label="sgpDoc[0].name_ru">
                <multiselect
                  v-model="selectedRegion"
                  :options="regions.filter((temp) => temp.nameRu !== 'Все')"
                  placeholder="Выберите район"
                  :allow-empty="false"
                  :show-labels="false"
                  label="nameRu"
                  @input="handleRegionSelect"
                />
              </b-form-group>
            </div>

            <div class="filter-block">
              <b-form-group :label="sgpDoc[1].name_ru">
                <multiselect
                  v-model="selectedDistrict"
                  :options="districts"
                  placeholder="Выберите сельский округ"
                  :allow-empty="false"
                  :show-labels="false"
                  label="nameRu"
                  @input="handleDistrictSelect"
                />
              </b-form-group>
            </div>
            <div class="filter-block">
              <b-form-group :label="sgpDoc[2].name_ru">
                <multiselect
                  v-model="selectedSnp"
                  :options="selectedDistrict ? snps : []"
                  placeholder="Выберите СНП"
                  :allow-empty="false"
                  :show-labels="false"
                  label="nameRu"
                />
              </b-form-group>
            </div>

            <div class="filter-block">
              <b-form-group :label="sgpDoc[3].name_ru">
                <multiselect
                  v-model="selectedReportType"
                  :options="reportTypes"
                  placeholder="Выберите форму"
                  :allow-empty="false"
                  :show-labels="false"
                  label="name"
                />
              </b-form-group>
            </div>

            <div class="filter-block" style="margin-top: 30px">
              <b-button variant="primary" @click="applyFilters">
                Применить
              </b-button>
              <b-button variant="secondary" @click="resetFilters">
                Сбросить
              </b-button>
            </div>
          </div>
        </b-dropdown>
        <div
          v-if="currentSnp"
          class="selected-snp"
          @click="openVillagePassportModal"
        >
          {{ currentSnp.nameRu }}
        </div>
        <div v-if="loadedReportType" class="selected-report">
          {{ loadedReportType.name }}
        </div>
        <template v-if="reconciliation">
          <div
            v-if="reconciliation.status === 'IN_PROCESS'"
            class="authorHistory__status ml-4"
            :class="reconciliation.status"
          >
            <span>{{
              $t(
                `modules.monitoringSnp.labelledStatuses.${loadedReportType.id}.${reconciliation.status}`
              )
            }}</span>
            <span>{{ ` - ${reconciliation.progress}%` }}</span>
          </div>
          <b-dropdown
            v-else
            class="authorHistory--passport"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <span
                class="authorHistory__status"
                :class="reconciliation.status"
                @click="loadHistory"
              >
                <span>{{
                  $t(
                    `modules.monitoringSnp.labelledStatuses.${loadedReportType.id}.${reconciliation.status}`
                  )
                }}</span>
                <span v-if="reconciliation.status === 'SEND_TO_REPROCESS'">
                  {{ ` - ${reconciliation.progress}%` }}
                </span>
              </span>
            </template>
            <div>
              <b-spinner
                v-if="historyLoading"
                small
                label="Small Spinner"
              ></b-spinner>
              <template v-else>
                <div class="authorHistory__list" v-if="historyData.length > 0">
                  <div
                    v-for="item of historyData"
                    class="row authorHistory__item"
                    :key="item.id"
                  >
                    <div
                      class="col-4 authorHistory__status"
                      :class="item.toStatus"
                    >
                      {{
                        $t(
                          `modules.monitoringSnp.shortStatuses.${item.toStatus}`
                        )
                      }}
                    </div>
                    <div class="col-4">{{ item.createdBy }}</div>
                    <div class="col-4">{{ formatDate(item.createdAt) }}</div>
                  </div>
                </div>
                <div v-else class="authorHistory__notFound">
                  История не найдена
                </div>
              </template>
            </div>
          </b-dropdown>
        </template>
      </div>

      <div
        v-if="
          reconciliation &&
          currentSnp &&
          currentSnp.code &&
          me.katoCodes.includes(currentSnp.code.toString())
        "
        class="filter-actions"
      >
        <b-button
          variant="success"
          @click="handleCheckBeforeSave"
          :disabled="!checkEditAvailable()"
        >
          Сохранить
        </b-button>
        <b-button
          variant="warning"
          @click="handleCopyColumn"
          :disabled="!checkEditAvailable()"
        >
          Дублировать
        </b-button>
        <b-button
          v-if="roles.VILLAGE"
          variant="primary"
          :disabled="
            !['IN_PROCESS', 'SEND_TO_REPROCESS'].includes(
              reconciliation.status
            ) || reconciliation.progress < 100
          "
          @click="handleSendToApprove"
        >
          На согласование
        </b-button>
        <b-button
          v-if="roles.DISTRICT || roles.DISTRICT_DEPARTMENTS"
          variant="success"
          :disabled="!canReconcile()"
          @click="handleReconcile"
        >
          Согласовать
        </b-button>
        <b-button
          v-if="roles.REGION || roles.REGION_DEPARTMENTS"
          variant="success"
          :disabled="!canApprove()"
          @click="handleApprove"
        >
          Утвердить
        </b-button>
        <b-button
          v-if="
            roles.DISTRICT ||
            roles.DISTRICT_DEPARTMENTS ||
            roles.REGION ||
            roles.REGION_DEPARTMENTS
          "
          variant="secondary"
          :disabled="!canSendToReprocess()"
          @click="handleSendToReprocess"
        >
          На доработку
        </b-button>
      </div>
    </div>
    <div class="table-container" v-if="!loading">
      <div class="b-table-sticky-header table-responsive-true">
        <table class="table b-table table-bordered b-table-no-border-collapse">
          <thead>
            <tr class="text-center">
              <th
                rowspan="2"
                colspan="6"
                style="text-align: left; cursor: pointer"
                @click="collapse"
              >
                <div style="display: flex; align-items: center">
                  <button
                    type="button"
                    style="margin-right: 9px"
                    class="btn btn-secondary"
                  >
                    <img v-if="openCount" src="./icons/open.svg" alt="" />
                    <img v-else src="./icons/closed.svg" alt="" />
                  </button>
                  <span>{{ tableFields[1].label }}</span>
                </div>
              </th>
              <th
                rowspan="2"
                colspan="5"
                class="text-right"
                v-if="showColumn(2)"
              >
                {{ tableFields[2].label }}
              </th>
              <th
                rowspan="2"
                colspan="5"
                class="text-right"
                v-if="showColumn(3)"
              >
                {{ tableFields[3].label }}
              </th>
              <th
                rowspan="2"
                colspan="5"
                class="text-right"
                v-if="showColumn(4)"
              >
                {{ tableFields[4].label }}
              </th>
              <th
                rowspan="2"
                colspan="5"
                class="text-right"
                v-if="showColumn(5)"
              >
                {{ tableFields[5].label }}
              </th>
              <th
                rowspan="2"
                colspan="5"
                class="text-right"
                v-if="showColumn(6)"
              >
                {{ tableFields[6].label }}
                {{
                  currentSnp &&
                  currentSnp.katoCode &&
                  currentSnp.katoCode.toString().startsWith("55")
                    ? ""
                    : isVisibleForHostName
                    ? ""
                    : isSko
                    ? " 11 м."
                    : ""
                }}
              </th>
              <th rowspan="2" colspan="1" v-if="checkKato() && !isApproved()" />
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, index) of globalArr">
              <tr :key="`item${item.id}`" v-if="item.visible">
                <td
                  colspan="6"
                  class="text-left"
                  :style="{
                    cursor: item.parent ? 'pointer' : 'inherit',
                    backgroundColor:
                      item.levels === 1 ? '#B0E0FF80' : '#B0E0FF33',
                  }"
                  @click="toggleVisibility(item)"
                >
                  <div style="display: flex" class="flex">
                    {{ "&emsp;&emsp;".repeat(item.levels - 1) }}
                    <button
                      v-if="item.parent"
                      type="button"
                      style="
                        margin-right: 9px;
                        min-width: 20px;
                        min-height: 20px;
                      "
                      class="btn btn-secondary"
                    >
                      <img v-if="item.open" src="./icons/open.svg" alt="" />
                      <img v-else src="./icons/closed.svg" alt="" />
                    </button>
                    <div :style="{ paddingLeft: !item.parent ? '29px' : 0 }">
                      {{ item.name }}
                    </div>
                  </div>
                </td>
                <td
                  colspan="5"
                  :style="{
                    backgroundColor:
                      item.levels === 1 ? '#fff5da' : '#FFF5DB50',
                  }"
                  v-if="showColumn(2)"
                >
                  <div
                    v-if="
                      !item.params ||
                      ['integer', 'float'].includes(item.params.data_type)
                    "
                  >
                    {{ getFormatted(item.year1) }}
                  </div>
                  <div v-else>
                    {{ item.year1 || "" }}
                  </div>
                </td>
                <td
                  colspan="5"
                  :style="{
                    backgroundColor:
                      item.levels === 1 ? '#B0E0FF80' : '#B0E0FF33',
                  }"
                  v-if="showColumn(3)"
                >
                  <div
                    v-if="
                      !item.params ||
                      ['integer', 'float'].includes(item.params.data_type)
                    "
                  >
                    {{ getFormatted(item.year2) }}
                  </div>
                  <div v-else>
                    {{ item.year2 || "" }}
                  </div>
                </td>
                <td
                  colspan="5"
                  :style="{
                    backgroundColor:
                      item.levels === 1 ? '#fff5da' : '#FFF5DB50',
                  }"
                  v-if="showColumn(4)"
                >
                  <div
                    v-if="
                      !item.params ||
                      ['integer', 'float'].includes(item.params.data_type)
                    "
                  >
                    {{ getFormatted(item.year3) }}
                  </div>
                  <div v-else>
                    {{ item.year3 || "" }}
                  </div>
                </td>
                <td
                  colspan="5"
                  :style="{
                    backgroundColor:
                      item.levels === 1 ? '#B0E0FF80' : '#B0E0FF33',
                  }"
                  v-if="showColumn(5)"
                >
                  <div class="edit-container">
                    <span
                      v-if="
                        !item.params ||
                        ['integer', 'float'].includes(item.params.data_type)
                      "
                    >
                      {{ getFormatted(item.year4) }}
                    </span>
                    <span v-else>{{ item.year4 || "" }}</span>
                  </div>
                </td>
                <td
                  colspan="5"
                  :style="{
                    backgroundColor:
                      item.levels === 1 ? '#fff5da' : '#FFF5DB50',
                  }"
                  v-if="showColumn(6)"
                >
                  <div
                    class="edit-container"
                    :key="`edit-container-${item.id}`"
                  >
                    <template
                      v-if="
                        checkEditAvailable() &&
                        item.params &&
                        ((item.params.values == null &&
                          item.params.formula == null &&
                          item.params.editable) ||
                          item.params.data_type === 'list')
                      "
                    >
                      <template
                        v-if="warningMap[index] && warningMap[index].length"
                      >
                        <img
                          :id="`warning-tooltip-trigger-5-${item.id}`"
                          src="./icons/warning.svg"
                          alt=""
                        />
                        <b-tooltip
                          :ref="`warning-tooltip-${item.id}`"
                          variant="light"
                          :target="`warning-tooltip-trigger-5-${item.id}`"
                          triggers="hover"
                        >
                          <h3 class="tooltip-header">Внимание!</h3>
                          <div class="tooltip-message">
                            {{ warningMap[index] }}
                          </div>
                          <div class="tooltip-actions">
                            <b-button
                              variant="success"
                              @click="okWarning(item, index)"
                              >OK
                            </b-button>
                            <span @click="closeWarning(item)">Отмена</span>
                          </div>
                        </b-tooltip>
                      </template>
                      <template
                        v-if="item.params && item.params.data_type === 'list'"
                      >
                        <template v-if="item.params.multiple_choice">
                          <multiselect
                            v-if="item.params.values"
                            v-model="item.year5"
                            :options="item.params.values"
                            :multiple="true"
                            :id="`tooltip-trigger-5-${item.id}`"
                            :class="
                              item.params &&
                              item.params.control &&
                              controlMap[index] &&
                              'input--control-error'
                            "
                            @input="handleMultipleSelect"
                            class="custom-multiselect"
                            placeholder="Выберите опции"
                          >
                            <template slot="selection" slot-scope="{ isOpen }">
                              <span class="multiselect__single" v-if="!isOpen">
                                {{
                                  Array.isArray(item.year5)
                                    ? item.year5.join(", ")
                                    : item.year5
                                }}
                              </span>
                            </template>
                          </multiselect>
                          <template
                            v-if="
                              item.params &&
                              item.params.control &&
                              controlMap[index]
                            "
                          >
                            <b-tooltip
                              variant="light"
                              :target="`tooltip-trigger-5-${item.id}`"
                              triggers="hover"
                            >
                              <h3 class="tooltip-header">Внимание!</h3>
                              <h4 class="tooltip-subheader">
                                Данные не соответствуют контролю:
                              </h4>
                              <div class="tooltip-message">
                                {{
                                  item.params.message ||
                                  item.params.control.message
                                }}
                              </div>
                            </b-tooltip>
                          </template>
                        </template>
                        <template v-else>
                          <select
                            v-if="item.params.values"
                            v-model="item.year5"
                            :id="`tooltip-trigger-5-${item.id}`"
                            :class="
                              item.params &&
                              item.params.control &&
                              controlMap[index] &&
                              'input--control-error'
                            "
                            :disabled="
                              item.params.control &&
                              item.params.control.depends &&
                              globalArr
                                .find(
                                  (row) =>
                                    row.id === item.params.control.depends
                                )
                                .year5.toLowerCase()
                                .includes('не')
                            "
                            @change="handleSelectChange(item, index)"
                          >
                            <template v-for="option of item.params.values">
                              <option :key="option" :value="option">
                                {{ option }}
                              </option>
                            </template>
                          </select>
                          <template
                            v-if="
                              item.params &&
                              item.params.control &&
                              controlMap[index]
                            "
                          >
                            <b-tooltip
                              variant="light"
                              :target="`tooltip-trigger-5-${item.id}`"
                              triggers="hover"
                            >
                              <h3 class="tooltip-header">Внимание!</h3>
                              <h4 class="tooltip-subheader">
                                Данные не соответствуют контролю:
                              </h4>
                              <div class="tooltip-message">
                                {{
                                  item.params.message ||
                                  item.params.control.message
                                }}
                              </div>
                            </b-tooltip>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <input
                          :type="
                            item.params &&
                            ['float'].includes(item.params.data_type)
                              ? 'number'
                              : 'text'
                          "
                          v-model="item.year5"
                          :class="
                            item.params &&
                            item.params.control &&
                            controlMap[index] &&
                            'input--control-error'
                          "
                          :id="`tooltip-trigger-5-${item.id}`"
                          @input="handleInputChange(item, index, 'year5')"
                        />
                        <template
                          v-if="
                            item.params &&
                            item.params.control &&
                            controlMap[index]
                          "
                        >
                          <b-tooltip
                            variant="light"
                            :target="`tooltip-trigger-5-${item.id}`"
                            triggers="hover"
                          >
                            <h3 class="tooltip-header">Внимание!</h3>
                            <h4 class="tooltip-subheader">
                              Данные не соответствуют контролю:
                            </h4>
                            <div class="tooltip-message">
                              {{
                                item.params.message ||
                                item.params.control.message
                              }}
                            </div>
                          </b-tooltip>
                        </template>
                      </template>
                    </template>
                    <div
                      v-else-if="
                        item.params &&
                        ['integer', 'float'].includes(item.params.data_type) &&
                        item.params.values
                      "
                      :id="`tooltip-trigger-5-${item.id}`"
                      :class="
                        item.params &&
                        item.params.control &&
                        controlMap[index] &&
                        'control-error'
                      "
                    >
                      <template
                        v-if="warningMap[index] && warningMap[index].length"
                      >
                        <img
                          :id="`warning-tooltip-trigger-5-${item.id}`"
                          src="./icons/warning.svg"
                          alt=""
                          class="warning-icon"
                        />
                        <b-tooltip
                          :ref="`warning-tooltip-${item.id}`"
                          variant="light"
                          :target="`warning-tooltip-trigger-5-${item.id}`"
                          triggers="hover"
                        >
                          <h3 class="tooltip-header">Внимание!</h3>
                          <div class="tooltip-message">
                            {{ warningMap[index] }}
                          </div>
                          <div class="tooltip-actions">
                            <b-button
                              variant="success"
                              @click="okWarning(item, index)"
                              >OK
                            </b-button>
                            <span @click="closeWarning(item)">Отмена</span>
                          </div>
                        </b-tooltip>
                      </template>
                      <span>{{ getFormatted(item.year5) }} </span>
                    </div>
                    <div
                      v-else
                      :id="`tooltip-trigger-5-${item.id}`"
                      :class="
                        item.params &&
                        item.params.control &&
                        controlMap[index] &&
                        'control-error'
                      "
                    >
                      <template
                        v-if="warningMap[index] && warningMap[index].length"
                      >
                        <img
                          :id="`warning-tooltip-trigger-5-${item.id}`"
                          src="./icons/warning.svg"
                          alt=""
                          class="warning-icon"
                        />
                        <b-tooltip
                          :ref="`warning-tooltip-${item.id}`"
                          variant="light"
                          :target="`warning-tooltip-trigger-5-${item.id}`"
                          triggers="hover"
                        >
                          <h3 class="tooltip-header">Внимание!</h3>
                          <div class="tooltip-message">
                            {{ warningMap[index] }}
                          </div>
                          <div class="tooltip-actions">
                            <b-button
                              variant="success"
                              @click="okWarning(item, index)"
                              >OK
                            </b-button>
                            <span @click="closeWarning(item)">Отмена</span>
                          </div>
                        </b-tooltip>
                      </template>
                      <span>{{ getFormatted(item.year5) }}</span>
                    </div>
                    <template
                      v-if="
                        item.params && item.params.control && controlMap[index]
                      "
                    >
                      <b-tooltip
                        variant="light"
                        :target="`tooltip-trigger-5-${item.id}`"
                        triggers="hover"
                      >
                        <h3 class="tooltip-header">Внимание!</h3>
                        <h4 class="tooltip-subheader">
                          Данные не соответствуют контролю:
                        </h4>
                        <div class="tooltip-message">
                          {{
                            item.params.message || item.params.control.message
                          }}
                        </div>
                      </b-tooltip>
                    </template>
                    <span
                      v-if="item.commentFeedId && checkKato() && !isApproved()"
                      class="icon-box"
                      style="cursor: pointer"
                      @click="handleOpenCommentModal(item)"
                    >
                      <img src="./icons/comment.svg" alt="" />
                    </span>
                  </div>
                </td>
                <td
                  colspan="1"
                  class="actions text-center"
                  v-if="checkKato() && !isApproved()"
                >
                  <span
                    :id="`vertical-dots-${item.id}`"
                    style="height: 20px; padding: 2px; cursor: pointer"
                    ><!--roles.VILLAGE &&-->
                    <img
                      :id="`comment-tooltip-trigger-${item.id}`"
                      src="./icons/3-vertical-dots.svg"
                      alt=""
                      style="height: 100%; width: 4px"
                    />
                  </span>
                  <b-tooltip
                    variant="light"
                    :target="`vertical-dots-${item.id}`"
                    triggers="hover"
                  >
                    <b-button
                      variant="light"
                      @click="handleOpenCommentModal(item)"
                      style="font-size: 12px"
                    >
                      Добавить/Открыть комментарий
                    </b-button>
                    <b-button
                      variant="light"
                      @click="handleClearValue(item)"
                      style="font-size: 12px"
                      v-if="canClearValue(item)"
                    >
                      Очистить
                    </b-button>
                  </b-tooltip>
                </td>
              </tr>
              <tr :key="`item${item.id}_addObject`" v-if="item.canAddObject">
                <td
                  colspan="100"
                  class="text-left"
                  :style="{ backgroundColor: '#B0E0FF33' }"
                >
                  <button class="addObjectBtn" @click="openParentModal(item)">
                    <i class="icon icon-plus-circle"></i>
                    Выбрать объект
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="spinner">
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      />
    </div>
    <b-modal
      v-model="commentModalOpen"
      @hide="handleCloseCommentModal"
      centered
      hide-header
      hide-footer
    >
      <div class="comment-modal-body">
        <div class="comment-modal-title">Добавить комментарий:</div>
        <template v-if="modalCommentItem && modalCommentItem.comments">
          <template v-for="comm of modalCommentItem.comments">
            <div :key="comm.id" class="comment-modal-comment">
              <div class="comment-modal-author">
                <span>{{
                  new Date(comm.createdAt).toLocaleString().slice(0, 10)
                }}</span>
                <span>{{ comm.author }}</span>
              </div>
              <div class="comment-modal-text">{{ comm.comment }}</div>
            </div>
          </template>
        </template>
        <b-form-textarea
          name="comment"
          id="comment"
          placeholder="Добавить комментарий"
          rows="5"
          max-rows="5"
          v-model="comment"
        />
      </div>
      <b-button variant="success" @click="handleSendCommentModal"
        >Отправить</b-button
      >
      <b-button variant="light" @click="handleCloseCommentModal"
        >Отменить</b-button
      >
    </b-modal>
    <b-modal v-model="parentModalVisible" centered hide-header hide-footer>
      <div class="parent-modal-body">
        <b-form-checkbox-group
          v-model="selectedChildren"
          :options="modalParentChildren"
          text-field="name"
          value-field="id"
          stacked
          @change="handleSelectedChildren"
        />

        <div class="text-center">
          <b-button @click="parentModalVisible = false" variant="success"
            >ОК</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal v-model="firstScenarioModal" centered hide-header hide-footer>
      <div class="confirmation-modal">
        <div>
          За отчетный период форма полностью заполнена, заменить данные?
        </div>
        <div>
          <b-button variant="success" @click="handleFirstScenario">
            Да
          </b-button>
          <b-button variant="secondary" @click="firstScenarioModal = false">
            Отмена
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="secondScenarioModal" centered hide-header hide-footer>
      <div class="confirmation-modal">
        <div>
          За отчетный период имеются заполнены данные.<br />
          Хотите заполнить во все ячейки или в пустые?
        </div>
        <div>
          <b-button variant="danger" @click="handleSecondScenarioAll"
            >Во все</b-button
          >
          <b-button variant="success" @click="handleSecondScenarioEmpty"
            >В пустые</b-button
          >
          <b-button variant="secondary" @click="secondScenarioModal = false"
            >Отмена</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal v-model="thirdScenarioModal" centered hide-header hide-footer>
      <div class="confirmation-modal">
        <div>
          Данные за предыдущий период будут скопированы в текущий. Вы уверены?
        </div>
        <div>
          <b-button variant="success" @click="handleThirdScenario">Да</b-button>
          <b-button variant="secondary" @click="thirdScenarioModal = false"
            >Отмена</b-button
          >
        </div>
      </div>
    </b-modal>
    <c-village-passport-modal
      ref="villagePassportModal"
    ></c-village-passport-modal>
  </div>
</template>

<script>
import { MonitoringSnpApi } from "@/modules/monitoring-snp/api/MonitoringSnpApi";
import {
  IN_PROCESS,
  SEND_TO_APPROVE,
  SEND_TO_REPROCESS,
  APPROVED,
  RECONCILED_BY_DEPARTMENTS,
  RECONCILED,
  APPROVED_BY_DEPARTMENTS,
  APPROVED_BY_ADDITIONAL_DEPARTMENTS,
  ROLE_VILLAGE,
  ROLE_REGION_DEPARTMENTS,
  ROLE_DISTRICT_DEPARTMENTS,
  ROLE_DISTRICT,
  ROLE_REGION,
} from "@/modules/monitoring-snp/consts/statuses";
import CVillagePassportModal from "@/modules/monitoring-snp/components/village-passport-modal";
import { isEmpty } from "lodash";
import moment from "moment";
import { Ax } from "@/utils";

export default {
  name: "passport-snp",
  props: ["reportTypes", "regions", "me", "moveTabs", "passportFilter"],
  components: {
    "c-village-passport-modal": CVillagePassportModal,
  },
  data() {
    return {
      tableFields: [
        {
          key: "action",
          label: " ",
        },
        {
          key: "name",
          label: "Показатель",
        },
        {
          key: "year1",
          label: "",
          variant: "foobar",
        },
        {
          key: "year2",
          label: "",
        },
        {
          key: "year3",
          label: "",
          variant: "foobar",
        },
        {
          key: "year4",
          label: "",
        },
        {
          key: "year5",
          label: "",
          variant: "foobar",
        },
        {
          key: "more",
          label: " ",
        },
      ],
      globalArr: [],
      prevGlobalArr: [],
      initialGlobalArr: [],
      roles: {
        VILLAGE: this.checkRole(ROLE_VILLAGE),
        DISTRICT_DEPARTMENTS: this.checkRole(ROLE_DISTRICT_DEPARTMENTS),
        DISTRICT: this.checkRole(ROLE_DISTRICT),
        REGION_DEPARTMENTS:
          this.checkRole(ROLE_REGION_DEPARTMENTS) ||
          this.checkRole("ROLE_usersnp_region_departments"),
        REGION: this.checkRole(ROLE_REGION),
      },
      tableRows: [],
      sgpDoc: [
        {
          name_ru: "Район",
          name_kk: "Райой",
          name_en: "District",
        },
        {
          name_ru: "Сельский округ",
          name_kk: "Сельский округ",
          name_en: "Rural district",
        },
        {
          name_ru: "СНП",
          name_kk: "СНП",
          name_en: "SNP",
        },
        {
          name_ru: "Форма",
          name_kk: "Форма",
          name_en: "Form",
        },
        {
          name_ru: "Период",
          name_kk: "Период",
          name_en: "Period",
        },
      ],
      statusColors: {
        SEND_TO_APPROVE: "#F79647",
        RECONCILED: "#2196F3",
        APPROVED: "#01AC50",
        SEND_TO_REPROCESS: "#C0504C",
        IN_PROCESS: "#6087A0",
      },
      selectedRegion: null,
      selectedDistrict: null,
      districts: [],
      selectedSnp: null,
      snps: [],
      selectedReportType: null,
      infoArr: [],
      allowAgreement: false,
      loading: false,
      editedItems: [],
      parentModalVisible: false,
      modalParent: null,
      selectedChildren: [],
      modalParentChildren: [],
      customFields: [],
      comment: "",
      commentModalOpen: false,
      selectedCommentItem: null,
      modalCommentItem: null,
      reconciliation: null,
      currentSnp: null,
      currentReportType: 1,
      openCount: 0,
      parentCount: 0,
      initialSnp: null,
      customFieldDependency: {},
      katoCodes: [],
      itemMap: {},
      controlCount: {},
      warningMap: [],
      controlMap: [],
      progress: 0,
      dependencyGraph: {},
      visited: {},
      loadedReportType: {
        id: 1,
        name: "Экономика",
      },
      isRequariedCheck: [],
      firstScenarioModal: false,
      secondScenarioModal: false,
      thirdScenarioModal: false,
      copyType: "all",
      populationData: {},
      resizeObserver: null,
      filterContainerHeight: 0,
      hostName: "",
      isVisibleForHostName: false,
      years: [],
      isSko: false,
      historyLoading: false,
      historyData: [],
      selectedItemsArr: [],
      reportInitItemsArr: [],
      currentYear: new Date().getFullYear(),
      openRows: {},
    };
  },
  mounted() {
    this.hostName = location.hostname.split(".")[0];
    if (this.hostName === "mangystau") {
      this.isVisibleForHostName = true;
    }
    if (this.hostName === "sko") this.isSko = true;
    this.observeHeight();
    this.getInitialSnpInfo();
    this.loadTableYears();
    this.loadInfo();
  },
  beforeUpdate() {
    this.globalArr.forEach((item, index) => {
      this.checkControl(item, index);
      this.checkSumOfRows();
    });
  },
  destroyed() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  watch: {
    modalParent: function () {
      this.modalParentChildren = this.modalParent
        ? this.globalArr.filter(
            (item) =>
              item.parent_id === this.modalParent.id && !item.customField
          )
        : [];
      this.parentModalVisible = this.modalParentChildren.length > 0;
    },
    parentModalVisible: function () {
      if (!this.parentModalVisible) {
        if (this.selectedChildren.length) {
          this.modalParent.open = true;
          const selectedItems = [];
          this.globalArr.forEach((item) => {
            if (item.id === this.modalParent.id) {
              item.pinned = true;
            }
            if (item.parent_id === this.modalParent.id) {
              if (!item.customField) {
                if (this.selectedChildren.includes(item.id)) {
                  item.visible = true;
                  item.pinned = true;
                  this.openChildren(item);
                  selectedItems.push(item);
                } else {
                  item.visible = false;
                  this.closeChildren(item);
                  this.removePinned(item);
                }
              } else {
                if (this.customFieldDependency[item.id]) {
                  item.visible = this.customFieldDependency[item.id].some(
                    (dep) => this.selectedChildren.includes(dep)
                  );
                } else {
                  item.visible = true;
                }
              }
            }
          });
          this.handleLoadReportInitItems(selectedItems);
        } else {
          this.handleReportReset(this.modalParent.id);
          this.globalArr.forEach((item) => {
            if (item.id === this.modalParent.id) {
              this.removePinned(item);
            }
            if (item.parent_id === this.modalParent.id) {
              item.visible = false;
              this.closeChildren(item);
            }
          });
          this.modalParent = null;
        }
      } else {
        this.selectedChildren = this.modalParentChildren
          .filter((item) => item.visible)
          .map((item) => item.id);
      }
    },
    passportFilter: function () {
      if (this.passportFilter) {
        this.selectedRegion = this.regions.find(
          (reg) => reg.code === this.passportFilter.regionCode
        );
        this.selectedSnp = this.passportFilter.selectedSnp;
        this.selectedReportType = this.passportFilter.selectedReportType;
        if (this.selectedRegion?.id) {
          this.loadDistricts(this.passportFilter.districtCode);
        }
        this.applyFilters();
      }
    },
  },
  methods: {
    observeHeight() {
      const filterContainer = this.$refs.filterContainer;
      this.resizeObserver = new ResizeObserver(() => {
        if (this.filterContainerHeight !== filterContainer.clientHeight) {
          this.filterContainerHeight = filterContainer.clientHeight;
          this.$root.$emit("filterContainer", filterContainer.clientHeight);
        }
      });
      this.resizeObserver.observe(filterContainer);
    },
    downloadFile() {
      let urlCodes = "";
      if (isEmpty(this.selectedReportType)) {
        this.makeToast("danger", "Вы не выбрали форму", "Ошибка");
        return;
      }

      if (isEmpty(this.selectedSnp)) {
        this.makeToast("danger", "Вы не выбрали СНП", "Ошибка");
        return;
      } else {
        urlCodes += `&codes=${this.selectedSnp.code}`;
      }

      const url = `/snp/api/v1/report/file/passport?reportId=${this.selectedReportType.id}${urlCodes}`;
      Ax(
        {
          url,
          method: "GET",
          data: null,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        },
        (data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.selectedSnp.nameRu}.xlsx`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log("err", error);
        }
      );
    },

    downloadAll() {
      let urlCodes = "";
      if (isEmpty(this.selectedSnp)) {
        this.makeToast("danger", "Вы не выбрали СНП", "Ошибка");
        return;
      } else {
        urlCodes += `codes=${this.selectedSnp.code}`;
      }

      const url = `/snp/api/v1/report/file/passport/batch?${urlCodes}`;

      Ax(
        {
          url,
          method: "GET",
          data: null,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        },
        (data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${this.selectedSnp.nameRu}.xlsx`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log("err", error);
        }
      );
    },

    downloadStaticFile() {
      const url = `/snp/api/v1/report/file/infoTemplate`;
      Ax(
        {
          url,
          method: "GET",
          data: null,
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        },
        (data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `СВОД 2022-I полугодие.xlsx`);
          document.body.appendChild(link);
          link.click();
        },
        (error) => {
          console.log("err", error);
        }
      );
    },

    getHistoricalData(code) {
      MonitoringSnpApi.get("/snp/api/v1/report/population/historical/data", {
        code,
      })
        .then((res) => {
          this.populationData = res.data.reduce(
            (acc, x) => ({ ...acc, [x.year]: x.population }),
            {}
          );
          this.recalculateFields("year5");
        })
        .catch((err) => console.log(err));
    },
    handleFirstScenario() {
      this.globalArr = this.globalArr.map((item, index) =>
        index !== this.globalArr.length - 1 &&
        item.params &&
        item.params.editable !== false
          ? {
              ...item,
              year5: item.year4,
            }
          : item
      );

      this.firstScenarioModal = false;
      this.recalculateFields();
    },
    handleSecondScenarioAll() {
      this.globalArr = this.globalArr.map((item, index) =>
        index !== this.globalArr.length - 1 &&
        item.params &&
        item.params.editable !== false
          ? {
              ...item,
              year5: item.year4,
            }
          : item
      );
      this.secondScenarioModal = false;
      this.recalculateFields();
    },
    handleSecondScenarioEmpty() {
      this.globalArr = this.globalArr.map((item, index) =>
        item.year5.length === 0 &&
        index !== this.globalArr.length - 1 &&
        item.params &&
        item.params.editable !== false
          ? {
              ...item,
              year5: item.year4,
            }
          : item
      );
      this.secondScenarioModal = false;
      this.recalculateFields();
    },
    handleThirdScenario() {
      this.globalArr = this.globalArr.map((item, index) =>
        index !== this.globalArr.length - 1
          ? {
              ...item,
              year5: item.year4,
            }
          : item
      );
      this.thirdScenarioModal = false;
      this.recalculateFields();
    },
    handleFinalConfirmationAccept() {
      this.handleCopyColumn();
      this.thirdScenarioModal = false;
    },
    handleCopyColumn() {
      if (this.reconciliation?.progress === 100) {
        this.firstScenarioModal = true;
      } else if (this.reconciliation?.progress === 0) {
        this.thirdScenarioModal = true;
      } else {
        this.secondScenarioModal = true;
      }
    },
    handleRegionSelect() {
      this.selectedDistrict = null;
      this.selectedSnp = null;
      this.selectedRegion && this.loadDistricts();
    },
    handleDistrictSelect() {
      this.selectedSnp = null;
      this.selectedDistrict && this.loadSnps();
    },
    // идет калькуляция
    calculateFormula(item, field = "year5") {
      const formula = item?.params?.values;
      if (formula?.length === 1 && formula[0].startsWith("population_")) {
        const populationIndex = +formula[0].at(-1);
        // const currentYear = new Date().getMonth() > 3 ? new Date().getFullYear() : new Date().getFullYear() - 1
        const currentYear = this.currentYear;
        const populationItem = this.globalArr.find((x) =>
          x.name.startsWith("Численность населения")
        );
        const lastYearPopulation = +populationItem?.year5;
        let res = null;
        if (field === "year5") {
          switch (populationIndex) {
            case 1:
              if (this.populationData[currentYear - 4]) {
                res =
                  (lastYearPopulation * 100) /
                  this.populationData[currentYear - 4];
              }
              break;
            case 2:
              if (
                this.populationData[currentYear - 5] &&
                this.populationData[currentYear - 9]
              ) {
                res =
                  (this.populationData[currentYear - 5] * 100) /
                  this.populationData[currentYear - 9];
              }
              break;
            case 3:
              if (this.populationData[currentYear - 9]) {
                res = lastYearPopulation - this.populationData[currentYear - 9];
              }
              break;
            case 4:
              if (this.populationData[currentYear - 9]) {
                res =
                  (lastYearPopulation * 100) /
                  this.populationData[currentYear - 9];
              }
              break;
            case 5:
              if (this.populationData[currentYear - 4]) {
                res = lastYearPopulation - this.populationData[currentYear - 4];
              }
              break;
            case 6:
              if (
                this.populationData[currentYear - 5] &&
                this.populationData[currentYear - 9]
              ) {
                res =
                  this.populationData[currentYear - 5] -
                  this.populationData[currentYear - 9];
              }
              break;
            case 7:
              if (
                this.populationData[currentYear] &&
                +this.populationData[currentYear] !== 0
              ) {
                const found = this.globalArr.find((x) => x.columnNumber === 78);
                if (found) {
                  res = +found.year5 / +this.populationData[currentYear];
                  if (
                    +this.populationData[currentYear] === 0 ||
                    this.populationData[currentYear] === ""
                  ) {
                    res = "0";
                  }
                } else {
                  res = "0";
                }
              } else {
                res = "0";
              }
              break;
            default:
              break;
          }
        }
        return res !== null ? parseFloat(res).toFixed(2) : item[field];
      }

      if (
        item?.params?.values?.length &&
        ["float", "integer"].includes(item?.params?.data_type)
      ) {
        // && item?.body[field] !== ""
        const stack = [];
        item?.params?.values.forEach((value) => {
          if (value.startsWith("id_")) {
            const item = this.globalArr.find((row) =>
              [row.controlId, row.id].includes(+value.slice(3))
            );
            stack.push(+item[field]);
          } else if (["+", "-", "*", "/"].includes(value)) {
            const last = stack[stack.length - 1];
            const prevLast = stack[stack.length - 2];
            stack.pop();
            stack.pop();
            if (value === "+") {
              stack.push(last + prevLast);
            } else if (value === "-") {
              stack.push(prevLast - last);
            } else if (value === "*") {
              stack.push(last * prevLast);
            } else {
              if (last === 0 || prevLast === 0) {
                stack.push(0);
              } else if (prevLast === last) {
                stack.push(1);
              } else {
                stack.push(prevLast / (last || 1));
              }
            }
          } else if ([">", "<", "="].includes(value)) {
            stack.length = 0;
          } else {
            stack.push(+value);
          }
        });
        return stack.length ? parseFloat(stack[0].toFixed(2)) : item[field];
      } else {
        return item[field];
      }
    },
    recalculateFields(field = "year5") {
      this.globalArr.forEach((item) => {
        if (
          ["integer", "float"].includes(item.params?.data_type) &&
          item.params?.values &&
          item.params?.values?.length
        ) {
          item[field] = [">", "<", "="].includes(
            item.params.values[item.params.values.length - 1]
          )
            ? item[field]
            : field === "year5"
            ? this.calculateFormula(item, field)
            : item[field];

          if (field === "year5") {
            this.updateControls();
          }
        }
        this.visited = {};
        this.updateDependencies(item.id, field);
      });
      this.globalArr.reverse().reverse();
    },
    updateControls() {
      this.globalArr.forEach((item, index) => {
        if (item.params?.control && item.parents.length === 0) {
          this.checkControl(item, index);
        }
      });
    },
    checkControl(item, index, field = "year5") {
      this.controlMap[index] = false;
      // 13.09 контроль полей
      if (item?.open) {
        if (item?.params?.control?.less_than) {
          const toCompare = this.globalArr.find((row) =>
            [row.controlId, row.id].includes(item.params?.control?.less_than)
          );
          this.controlMap[index] |= toCompare
            ? +toCompare[field] < +item[field]
            : false;
        }
        if (item?.params?.control?.more_than) {
          const toCompare = this.globalArr.find((row) =>
            [row.controlId, row.id].includes(item.params?.control?.more_than)
          );
          this.controlMap[index] |= toCompare
            ? +toCompare[field] > +item[field]
            : false;
        }
        if (item?.params?.control?.max_width) {
          this.controlMap[index] |=
            item[field].length < item.params?.control?.max_width;
        }
        if (item?.params?.control?.report_year) {
          this.controlMap[index] |= +item[field] > this.lastYear;
        }
        if (
          item?.params?.values?.length &&
          ["integer", "float"].includes(item.params.data_type)
        ) {
          const lastValuesItem =
            item.params.values?.length &&
            item?.params?.values[item?.params?.values?.length - 1];
          if (lastValuesItem && [">", "<", "="].includes(lastValuesItem)) {
            let sum = 0;
            item?.params?.values.forEach((value, index) => {
              if (
                value.startsWith("id_") &&
                item.params.values.length - 2 !== index
              ) {
                const row = this.globalArr.find((row) =>
                  [row.id, row.controlId].includes(+value.slice(3))
                );
                sum += row ? +row[field] : 0;
              }
            });
            const parentId =
              +item.params.values[item.params.values.length - 2].slice(3);
            const toCompare = this.globalArr.find((row) =>
              [row.id, row.controlId].includes(parentId)
            );
            if (
              (lastValuesItem === "<" && sum > +toCompare[field]) ||
              (lastValuesItem === ">" && sum < +toCompare[field]) ||
              (lastValuesItem === "=" && sum !== +toCompare[field])
            ) {
              item?.params?.values.forEach((value) => {
                if (value.startsWith("id_") && +value.slice(3) !== parentId) {
                  const rowIndex = this.globalArr.findIndex((row) =>
                    [row.id, row.controlId].includes(+value.slice(3))
                  );
                  this.controlMap[rowIndex] = true;
                }
              });
            }
          }
        }
      }
      this.controlMap.reverse().reverse();
    },
    handleInputChange(item, index, field = "year5") {
      let lastEntered = "";
      if (item) {
        if (item[field] === "") {
          lastEntered = "";
        } else {
          lastEntered = item[field][item[field].length - 1];
        }
        if (item.params?.data_type === "integer") {
          item[field] = item[field].replace(/[^0-9]/g, "");
          item[field] = item[field].replace(/(\..*)\./g, "$1");
        }
        if (lastEntered !== ".") {
          if (field === "year5") {
            this.checkControl(item, index, field);
            this.checkWarning(item, index, field);
          }

          this.visited = {};
          this.updateDependencies(item.id, field);
        }
      }
    },
    updateDependencies(id, field, checkWarning = false) {
      !this.visited[id] &&
        this.dependencyGraph[id]?.forEach((depId) => {
          this.visited[id] = true;
          const dep = this.globalArr.find((item) => item.id === depId);
          const depIndex = this.globalArr.findIndex(
            (item) => item.id === depId
          );
          if (field === "year5") {
            this.globalArr = [
              ...this.globalArr.map((item, index) =>
                index === depIndex
                  ? { ...item, [field]: this.calculateFormula(item, field) }
                  : { ...item }
              ),
            ];

            this.checkControl(dep, depIndex);
            checkWarning && this.checkWarning(dep, depIndex);
          }
          this.updateDependencies(
            dep?.id,
            field,
            ["integer", "float"].includes(dep?.params?.data_type) &&
              dep?.params?.values?.length
          );
        });
    },
    checkWarning(item, index) {
      if (item?.params?.warn && item.year4 !== "" && item.year4 !== null) {
        const diff = Math.abs(
          100 - (parseInt(item.year5) * 100) / parseInt(item.year4)
        );
        if (diff) {
          this.warningMap[index] =
            diff > 30 ? "Разница с прошлогодней цифрой больше чем 30%" : "";
        } else {
          this.warningMap[index] = "";
        }
      } else {
        this.warningMap[index] = "";
      }
      this.warningMap.reverse().reverse();
      localStorage.setItem(
        `warningMap-${this.selectedReportType?.id}`,
        this.warningMap
      );
    },
    okWarning(item, index) {
      this.warningMap[index] = "";
      this.initialGlobalArr[index] = JSON.parse(
        JSON.stringify(this.globalArr[index])
      );
      this.warningMap.reverse().reverse();
      this.closeWarning(item);
    },
    closeWarning(item) {
      this.$refs[`warning-tooltip-${item.id}`][0]["_events"].close[0]();
    },
    checkRole(role) {
      if (this.me?.roles) {
        return this.me.roles.find((r) => r.includes(role));
      }
      return false;
    },
    checkKato() {
      return this.me.katoCodes.includes(this.currentSnp?.code?.toString());
    },
    isApproved() {
      return this.reconciliation?.status === APPROVED;
    },
    checkEditAvailable() {
      return (
        (this.roles.VILLAGE || this.roles.DISTRICT) &&
        [IN_PROCESS, SEND_TO_REPROCESS].includes(this.reconciliation?.status) &&
        this.me.katoCodes.includes(this.currentSnp?.code?.toString())
      );
    },
    compareArrays(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          return false;
        }
      }

      return true;
    },
    checkDependencies(id) {
      const queue = [id];
      const visited = {};

      while (queue.length) {
        const front = queue.shift();
        visited[front] = true;
        const frontItem = this.globalArr.find((item) => item.id === front);
        const frontIndex = this.globalArr.findIndex(
          (item) => item.id === front
        );
        if (this.controlMap[frontIndex]) {
          return frontItem;
        }

        this.dependencyGraph[front]?.forEach((item) => {
          if (!visited[item]) {
            queue.push(item);
          }
        });
      }

      return false;
    },
    globalArrWatcher() {
      for (let i = 0; i < this.globalArr.length; i++) {
        if (
          ["integer", "string", "float", "list"].includes(
            this.globalArr[i].params?.data_type
          ) ||
          this.globalArr[i].params?.multiple_choice
        ) {
          const isListTypeString = Array.isArray(
            this.globalArr[i].params?.values
          )
            ? this.globalArr[i].params?.values[0].startsWith("нет")
            : false;

          if (this.controlMap[i]) {
            this.makeToast(
              "info",
              `Данное поле не проходит контроли для сохранения: ${this.globalArr[i].name}`,
              "Ошибка при попытке сохранения"
            );
            continue;
          }

          if (
            this.globalArr[i].year4 != null ||
            this.prevGlobalArr[i].year4 != null
          ) {
            if (
              this.globalArr[i].year4 !== this.prevGlobalArr[i].year4 &&
              this.currentReportType === 3 &&
              [
                "до 16",
                "16-29",
                "30-57",
                "30-62",
                "58 и старше",
                "63 и старше",
              ].includes(this.globalArr[i].name)
            ) {
              this.editedItems.push({
                columnNumber: this.globalArr[i].columnNumber,
                headerId: this.globalArr[i].id,
                reportYear: this.previousYear,
                value: this.globalArr[i].year4 || null,
                name: this.globalArr[i].name,
                isTypeString: isListTypeString,
                typeField: this.globalArr[i].params?.data_type,
              });
            }
          }
          if (
            Array.isArray(this.globalArr[i].year5) &&
            Array.isArray(this.prevGlobalArr[i].year5)
              ? !this.compareArrays(
                  this.globalArr[i].year5,
                  this.prevGlobalArr[i].year5
                )
              : this.globalArr[i].year5 !== this.prevGlobalArr[i].year5
          ) {
            const checkDep = this.checkDependencies(this.globalArr[i].id);
            if (!checkDep) {
              this.editedItems.push({
                columnNumber: this.globalArr[i].columnNumber,
                headerId: this.globalArr[i].id,
                controlId: this.globalArr[i].controlId,
                reportYear: this.lastYear,
                value: Array.isArray(this.globalArr[i].year5)
                  ? this.globalArr[i].year5.join(", ")
                  : this.globalArr[i].year5 == ""
                  ? null
                  : this.globalArr[i].year5,
                name: this.globalArr[i].name,
                isTypeString: isListTypeString,
                typeField: this.globalArr[i].params?.data_type,
                index: i,
              });
            } else {
              this.editedItems.push({
                columnNumber: this.globalArr[i].columnNumber,
                headerId: this.globalArr[i].id,
                controlId: this.globalArr[i].controlId,
                reportYear: this.lastYear,
                value: Array.isArray(this.globalArr[i].year5)
                  ? this.globalArr[i].year5.join(", ")
                  : this.globalArr[i].year5 == ""
                  ? null
                  : this.globalArr[i].year5,
                name: this.globalArr[i].name,
                isTypeString: isListTypeString,
                typeField: this.globalArr[i].params?.data_type,
                index: i,
              });
            }
          }

          if (
            this.editedItems.length &&
            this.editedItems[this.editedItems.length - 1].value === null &&
            this.editedItems[this.editedItems.length - 1].headerId ===
              this.globalArr[i].id &&
            this.globalArr[i].params?.nullable
          ) {
            this.editedItems[this.editedItems.length - 1].value = null;
          }
        }
      }
    },
    handleMultipleSelect(value, id) {
      const lastValue = value[value.length - 1];
      const item = this.globalArr.find((row) => row.id === +id.slice(18));
      if (lastValue === "Нет") {
        item.year5 = ["Нет"];
      } else {
        item.year5 =
          item.year5[0] === "Нет"
            ? [lastValue]
            : item.year5.filter((val) => val.length > 0);
      }
    },
    copyArray(from) {
      const to = [];
      from.forEach((item) => {
        to.push(JSON.parse(JSON.stringify(item)));
      });
      return to.slice();
    },
    getFormatted(str) {
      if (!str) {
        return str;
      }
      if (typeof str === "string") {
        for (const ch of str) {
          if (!(ch >= "0" && ch <= "9") && ch !== ".") {
            return str;
          }
        }
      }
      if (Array.isArray(str)) {
        return str.join(", ");
      }
      return isNaN(str) ? str : Number(str).toLocaleString("ru-RU");
    },
    loadTableYears() {
      MonitoringSnpApi.get("/snp/api/v1/admin/years")
        .then((res) => {
          this.years = res?.data?.sort();
          const years = res?.data?.reverse();
          this.currentYear = years[0];
          this.tableFields[2].label = years[4];
          this.tableFields[3].label = years[3];
          this.tableFields[4].label = years[2];
          this.tableFields[5].label = years[1];
          this.tableFields[6].label = years[0];
        })
        .catch(() => {});
    },
    handleCustomFields(item, level) {
      let customParents = [];
      if (
        (!this.selectedReportType ||
          [9].includes(this.selectedReportType.id)) &&
        level === 1
      ) {
        item.data?.forEach((child) => {
          if (child.customField) {
            this.customFieldDependency[child.id] = customParents;
            customParents = [];
          } else {
            customParents.push(child.id);
          }
        });
      }
    },
    getInitialSnpInfo() {
      return MonitoringSnpApi.get("/snp/api/v1/reconciliations/", {
        katoCode: this.me.katoCodes[this.me.katoCodes.length - 1],
      })
        .then((res) => {
          const data = {
            ...res.data[0],
            code: res.data[0].katoCode,
            nameRu: res.data[0].snpNameRu,
          };
          this.me.initialSnp = data;
          this.currentSnp = data;
          this.reconciliation = data;
          this.selectedRegion = this.regions.find(
            (reg) => reg.code === this.me.initialSnp.regionKatoCode
          );
          this.selectedSnp = data;
          this.selectedReportType = {
            id: 1,
            name: "Экономика",
          };

          MonitoringSnpApi.get("/snp/api/v1/admin/dict-kato", {
            parentId: this.selectedRegion?.id,
          })
            .then((res) => {
              this.districts = res.data;
              this.selectedDistrict = this.districts.find(
                (dist) => dist.code === this.me.initialSnp.districtKatoCode
              );

              MonitoringSnpApi.get("/snp/api/v1/admin/dict-kato", {
                parentId: this.selectedDistrict?.id,
              })
                .then((res) => {
                  this.snps = res.data;
                })
                .catch(() => {});
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    handleSelectChange(item, index) {
      const dependent = this.globalArr.find(
        (row) => row.params?.control?.depends === item.id
      );
      if (dependent && item.year5.toLowerCase().includes("не")) {
        dependent.year5 = "";
      }
      this.checkControl(item, index);
      this.visited = {};
      this.updateDependencies(item.id, "year5");
    },
    loadGlobalArr(item, level = 1) {
      this.handleCustomFields(item, level);
      item?.data.forEach((child) => {
        if (child.header.toLowerCase().trim() === "всего") {
          const row = this.globalArr.find((r) => r.id === item.id);
          row.controlId = child.id;
          row.year1 = child.body.length > 4 ? child.body[4].text : "";
          row.year2 = child.body.length > 3 ? child.body[3].text : "";
          row.year3 = child.body.length > 2 ? child.body[2].text : "";
          row.year4 = child.body.length > 1 ? child.body[1].text : "";
          row.year5 =
            child.body.length > 0
              ? ["integer", "float"].includes(child.params?.data_type) &&
                child.body[0].text
                ? parseFloat(child.body[0].text).toString()
                : child.body[0].text
              : "";
          row.params = child.params;
          this.itemMap[child.id] = row;
          child.data.forEach((ch) => {
            const newItem = {
              id: ch.id,
              name: ch.header,
              year1: ch.body.length > 4 ? ch.body[4].text : "",
              year2: ch.body.length > 3 ? ch.body[3].text : "",
              year3: ch.body.length > 2 ? ch.body[2].text : "",
              year4: ch.body.length > 1 ? ch.body[1].text : "",
              year5:
                child.body.length > 0
                  ? ["integer", "float"].includes(child.params?.data_type) &&
                    child.body[0].text
                    ? parseFloat(child.body[0].text).toString()
                    : child.body[0].text
                  : "",
              childCount: ch.data.length,
              parent: ch.data.length > 0,
              parents: [...row.parents, row.id],
              parent_id: item.id,
              params: ch.params,
              open: this.openRows[ch.id]?.open || false,
              visible: this.openRows[ch.id]?.visible || false,
              levels: level + 1,
              customField: child.customField,
              controlParentId: row.id,
              commentFeedId: ch.commentFeedId,
              columnNumber: ch.columnNumber,
              pinned: ch.pinned,
            };
            newItem.parent && this.parentCount++;
            this.itemMap[newItem.id] = newItem;
            this.globalArr.push(newItem);
            this.warningMap[this.globalArr.length - 1] = "";
            this.controlMap[this.globalArr.length - 1] = false;
          });
        } else {
          const row = this.globalArr.find((r) => r.id === item.id);
          const newItem = {
            id: child.id,
            name: child.header,
            year1: child.body.length > 4 ? child.body[4].text : "",
            year2: child.body.length > 3 ? child.body[3].text : "",
            year3: child.body.length > 2 ? child.body[2].text : "",
            year4: child.body.length > 1 ? child.body[1].text : "",
            year5:
              child.body.length > 0
                ? ["integer", "float"].includes(child.params?.data_type) &&
                  child.body[0].text
                  ? parseFloat(child.body[0].text).toString()
                  : child.body[0].text
                : "",
            childCount: child.data.length,
            parent: child.data.length > 0,
            parents: [...row.parents, row.id],
            parent_id: item.id,
            params: child.params,
            open: this.openRows[child.id]?.open || false,
            visible: this.openRows[child.id]?.visible || false,
            levels: level + 1,
            customField: child.customField,
            commentFeedId: child.commentFeedId,
            columnNumber: child.columnNumber,
            pinned: child.pinned,
          };
          if (!child.customField) {
            this.customFields.push(child);
            for (let i = this.globalArr.length - 1; i >= 0; i--) {
              if (this.globalArr[i].id === item.id) {
                this.globalArr[i].hasCustomChild = true;
                break;
              }
            }
          }
          newItem.year5 = newItem.params?.multiple_choice
            ? newItem.year5.split(", ")
            : newItem.year5;
          newItem.parent && this.parentCount++;
          if (newItem.params?.nullable) {
            newItem.year1 = newItem.year1.length > 0 ? newItem.year1 : "нет";
            newItem.year2 = newItem.year2.length > 0 ? newItem.year2 : "нет";
            newItem.year3 = newItem.year3.length > 0 ? newItem.year3 : "нет";
            newItem.year4 = newItem.year4.length > 0 ? newItem.year4 : "нет";
            newItem.year5 = newItem.year5.length > 0 ? newItem.year5 : "нет";
          }
          this.itemMap[newItem.id] = newItem;
          this.globalArr.push(newItem);
          this.warningMap[this.globalArr.length - 1] = "";
          this.controlMap[this.globalArr.length - 1] = false;
          this.loadGlobalArr(child, level + 1);
        }
      });
    },
    loadDistricts(districtCode) {
      MonitoringSnpApi.get("/snp/api/v1/admin/dict-kato", {
        parentId: this.selectedRegion?.id,
      })
        .then((res) => {
          this.districts = res.data;
          if (districtCode && this.districts?.length) {
            this.selectedDistrict = this.districts.find(
              (dist) => dist.code === districtCode
            );
            if (this.selectedDistrict?.id) {
              this.loadSnps(this.passportFilter.snpCode);
            }
          }
        })
        .catch(() => {});
    },
    loadSnps(snpCode) {
      MonitoringSnpApi.get("/snp/api/v1/admin/dict-kato", {
        parentId: this.selectedDistrict?.id,
      })
        .then((res) => {
          this.snps = res.data;
          if (snpCode && this.snps?.length) {
            this.selectedSnp = this.snps.find((dist) => dist.code === snpCode);
          }
        })
        .catch(() => {});
    },
    makeToast(variant = null, toastBody, title) {
      this.$bvToast.toast(toastBody, {
        title: title,
        variant: variant,
        autoHideDelay: 4000,
        solid: true,
      });
    },
    loadReconciliation(code, reportId) {
      this.reconciliation = null;
      MonitoringSnpApi.get(`/snp/api/v1/reconciliations/${code}/${reportId}`)
        .then((res) => {
          this.reconciliation = res.data;
          this.progress = res.data.progress;
        })
        .catch(() =>
          this.makeToast(
            "danger",
            "Ошибка запроса",
            "Не удается найти согласование для этого СНП"
          )
        );
    },
    updateFormProgress() {
      MonitoringSnpApi.get(
        `/snp/api/v1/reconciliations/${this.currentSnp.code}/${this.currentReportType}`
      )
        .then((res) => (this.reconciliation = res.data))
        .catch(() =>
          this.makeToast(
            "danger",
            "Ошибка запроса",
            "Не удается найти согласование для этого СНП"
          )
        );
    },
    mapToGlobalArr(res) {
      this.openRows = this.globalArr.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: { open: item.open, visible: item.visible },
        }),
        {}
      );
      this.controlMap = [];
      this.warningMap = [];
      this.loading = true;
      const code =
        this.currentSnp?.code ||
        this.me.katoCodes[this.me.katoCodes.length - 1];
      const reportId = this.selectedReportType?.id || 1;
      this.infoArr = res.data;
      this.globalArr = [];
      this.customFields = [];
      this.infoArr?.data?.forEach((item) => {
        const newItem = {
          ...item,
          name: item.header,
          year1: item.body.length > 4 ? item.body[4].text : "",
          year2: item.body.length > 3 ? item.body[3].text : "",
          year3: item.body.length > 2 ? item.body[2].text : "",
          year4: item.body.length > 1 ? item.body[1].text : "",
          year5:
            item.body.length > 0
              ? ["integer", "float"].includes(item.params?.data_type) &&
                item.body[0].text
                ? parseFloat(item.body[0].text).toString()
                : item.body[0].text
              : "",
          childCount: item.data.length,
          parent: item.data.length > 0,
          parents: [],
          params: item.params,
          parent_id: 0,
          open: this.openRows[item.id]?.open || false,
          visible: true,
          levels: 1,
          hasCustomChild: false,
        };
        newItem.parent && this.parentCount++;
        this.itemMap[newItem.id] = newItem;
        this.globalArr.push(newItem);
        this.warningMap[this.globalArr.length - 1] = "";
        this.controlMap[this.globalArr.length - 1] = false;
        this.loadGlobalArr(item);
      });
      this.loadReconciliation(code, reportId);
      this.buildDependencyGraph();
      this.recalculateFields("year1");
      this.recalculateFields("year2");
      this.recalculateFields("year3");
      this.recalculateFields("year4");
      this.recalculateFields("year5");

      this.prevGlobalArr = this.copyArray(this.globalArr);
      this.globalArr.forEach((item, index) => {
        this.warningMap[index] = "";
      });
      if (!!this.selectedReportType?.id) {
        this.loadedReportType = { ...this.selectedReportType };

        if (
          localStorage.hasOwnProperty(
            `warningMap-${this.selectedReportType.id}`
          )
        ) {
          this.warningMap = localStorage
            .getItem(`warningMap-${this.selectedReportType.id}`)
            .split(",");
        }
      }

      this.getHistoricalData(code);
    },
    loadInfo() {
      const code =
        this.currentSnp?.code ||
        this.me.katoCodes[this.me.katoCodes.length - 1];
      const reportId = this.selectedReportType?.id || 1;
      MonitoringSnpApi.post("/snp/api/v1/admin/form-info", { reportId, code })
        .then((res) => {
          this.mapToGlobalArr(res);
        })
        .catch((err) => {
          this.makeToast(
            "danger",
            "По указанным фильтрам не существует данных!!!",
            "Ошибка"
          );
        })
        .finally(() => (this.loading = false));
    },
    buildDependencyGraph() {
      if (!this.globalArr.length) {
        return;
      }
      this.visited = {};
      this.dependencyGraph = {};

      this.globalArr.forEach((item) => {
        this.dependencyGraph[item.id] = [];
        if (item.controlId) {
          this.dependencyGraph[item.controlId] = [];
        }
      });

      this.globalArr.forEach((item, index) => {
        if (
          ["integer", "float"].includes(item.params?.data_type) &&
          item.params.formula?.length &&
          item.params.values?.length
        ) {
          const lastValuesItem =
            item.params.values[item.params.values.length - 1];
          if ([">", "<", "="].includes(lastValuesItem)) {
            const parentId =
              +item.params.values[item.params.values.length - 2].slice(3);
            const checkId = this.globalArr.find(
              (row) => row.controlId === parentId
            );
            for (let i = 0; i < item.params.values.length - 2; i++) {
              const child = item.params.values[i];
              if (child.startsWith("id_")) {
                this.dependencyGraph[+child.slice(3)].push(
                  checkId?.id || parentId
                );
                this.dependencyGraph[checkId?.id || parentId]?.push(
                  +child.slice(3)
                );
              }
            }
          } else {
            item.params.values.forEach((child) => {
              if (child.startsWith("id_")) {
                const childId = +child.slice(3);
                const checkId = this.globalArr.find(
                  (row) => row.controlId === childId
                );
                this.dependencyGraph[checkId?.id || childId]?.push(item.id);
              }
            });
          }
        }

        if (item.params?.control?.more_than) {
          const checkId = this.globalArr.find(
            (row) => row.controlId === item.params.control.more_than
          );
          this.dependencyGraph[item.id].push(
            checkId?.id || item.params.control.more_than
          );
          this.dependencyGraph[
            checkId?.id || item.params.control.more_than
          ].push(item.id);
        }

        if (item.params?.control?.less_than) {
          const checkId = this.globalArr.find(
            (row) => row.controlId === item.params.control.less_than
          );
          this.dependencyGraph[item.id].push(
            checkId?.id || item.params.control.less_than
          );
          this.dependencyGraph[
            checkId?.id || item.params.control.less_than
          ]?.push(item?.id);
        }

        if (item.params?.control?.depends) {
          const checkId = this.globalArr.find(
            (row) => row.controlId === item.params.control.depends
          );
          this.dependencyGraph[item.id].push(
            checkId?.id || item.params.control.depends
          );
          this.dependencyGraph[checkId?.id || item.params.control.depends].push(
            item.id
          );
        }

        if (
          item.params?.values &&
          item.params?.values[0].startsWith("population_")
        ) {
          if (
            this.selectedReportType?.id === 1 ||
            this.loadedReportType?.id === 1
          ) {
            const populationItem = this.globalArr.find(
              (x) => x.columnNumber === 78
            );
            populationItem &&
              this.dependencyGraph[populationItem?.id]?.push(item?.id);
          } else {
            const populationItem = this.globalArr.find((x) =>
              x.name.startsWith("Численность населения")
            );
            this.dependencyGraph[populationItem?.id]?.push(item?.id);
          }
        }
      });
    },
    reloadData() {
      this.infoArr = this.globalArr = [];
      this.openCount = 0;
      this.selectedChildren = [];
      this.loadInfo();
    },
    applyFilters() {
      if (
        this.selectedSnp &&
        this.selectedDistrict &&
        this.selectedReportType
      ) {
        if (this.selectedReportType.id === 7) {
          this.moveTabs(3);
          this.$emit("passport", {
            selectedRegion: this.selectedRegion,
            selectedReportType: {
              id: 13,
              name: "Реестр дорог",
            },
          });
        } else {
          this.editedItems = [];
          this.currentSnp = this.selectedSnp;
          this.currentReportType = this.selectedReportType.id;
          this.reloadData();
          this.hideFiltersPanel();

          if (this.selectedReportType.id === 3) {
            setTimeout(() => {
              this.reloadData();
            }, 250);
          }
        }
      } else {
        this.makeToast(
          "info",
          "Все поля должны быть заполнены",
          "Не удалось применить фильтры"
        );
      }
    },
    resetFilters() {
      this.getInitialSnpInfo().then(() => {
        this.hideFiltersPanel();
        this.reloadData();
      });
    },
    hideFiltersPanel() {
      this.$refs.drop.hide(true);
    },
    collapse() {
      const openAll = this.openCount === 0;
      this.globalArr.forEach((item) => {
        if (item.parent) {
          if (openAll) {
            this.openChildren(item, true);
          } else if (!openAll && item.open) {
            this.closeChildren(item);
          }
        }
      });
      // this.selectedChildren.length > 0 && (
      //     this.customFields.forEach(field => {
      //         if (!this.selectedChildren.includes(field.id)) {
      //             this.closeChildren(field)
      //             this.globalArr.find(r => r.id === field.id).visible = false
      //         }
      //     })
      // )
      this.openCount = openAll ? this.parentCount : 0;
    },
    removePinned(item) {
      item.pinned = false;
      for (const row of this.globalArr) {
        if (row.pinned && row.parent_id === item.id) {
          row.pinned = false;
          row.parent && this.removePinned(row);
        }
      }
    },
    closeChildren(item) {
      this.openCount--;
      item.open = false;
      if (item.canAddObject) {
        delete item.canAddObject;
      }
      for (const row of this.globalArr) {
        if (row.parent_id === item.id) {
          row.visible = false;
          // 13.09 контроль полей
          if (!row.parent) {
            row.open = item.open;
          }
          row.parent && row.open && this.closeChildren(row);
        }
      }
    },
    isVisibleCustomField(item, pinnedItems) {
      if (this.customFieldDependency[item.id]) {
        return this.customFieldDependency[item.id].some((dep) =>
          pinnedItems.includes(dep)
        );
      } else {
        return true;
      }
    },
    openChildren(item, checkPinned) {
      this.openCount++;
      item.open = true;
      if (item.hasCustomChild) {
        item.canAddObject = [IN_PROCESS, SEND_TO_REPROCESS].includes(
          this.reconciliation?.status
        );
      }
      const pinned = this.globalArr
        .filter((row) => row.pinned && row.parent_id === item.id)
        .map((row) => row.id);
      for (const row of this.globalArr) {
        if (row.parent_id === item.id) {
          const parentId =
            checkPinned &&
            row.parents?.find((id) => {
              return this.globalArr.some(
                (r) => r.id === id && r.hasCustomChild
              );
            });
          const parent =
            parentId && this.globalArr.find((r) => r.id === parentId);
          if (
            !checkPinned ||
            (checkPinned &&
              (!parent?.hasCustomChild ||
                (parent?.hasCustomChild && (row.pinned || row.customField))))
          ) {
            // 13.09 контроль полей
            if (!row.parent) {
              row.open = item.open;
            }
            if (item.hasCustomChild && row.customField) {
              row.visible = this.isVisibleCustomField(row, pinned);
            } else {
              row.visible =
                (checkPinned && parent?.hasCustomChild) || item.hasCustomChild
                  ? row.pinned
                  : item.open;
            }
          }
        }
      }
    },
    toggleVisibility(item) {
      item.open = !item.open;
      if (item.open) {
        this.openChildren(item);
      } else {
        this.closeChildren(item);
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
      if (!item.visible) {
        return "is-hidden";
      }
    },
    canSendToReprocess() {
      const { DISTRICT, DISTRICT_DEPARTMENTS, REGION, REGION_DEPARTMENTS } =
        this.roles;
      return (
        (DISTRICT_DEPARTMENTS &&
          this.reconciliation?.status === SEND_TO_APPROVE) ||
        (DISTRICT &&
          this.reconciliation?.status === RECONCILED_BY_DEPARTMENTS) ||
        (REGION_DEPARTMENTS && this.reconciliation?.status === RECONCILED) ||
        (REGION && this.reconciliation?.status === APPROVED_BY_DEPARTMENTS) ||
        (REGION_DEPARTMENTS &&
          this.reconciliation?.status === APPROVED_BY_ADDITIONAL_DEPARTMENTS)
      );
    },
    canReconcile() {
      const { DISTRICT, DISTRICT_DEPARTMENTS } = this.roles;
      if (this.isVisibleForHostName) {
        return (
          DISTRICT_DEPARTMENTS &&
          this.reconciliation?.status === SEND_TO_APPROVE
        );
      } else {
        return (
          (DISTRICT &&
            this.reconciliation?.status === RECONCILED_BY_DEPARTMENTS) ||
          (DISTRICT_DEPARTMENTS &&
            this.reconciliation?.status === SEND_TO_APPROVE)
        );
      }
    },
    handleReconcile() {
      const status =
        this.roles.DISTRICT_DEPARTMENTS &&
        this.reconciliation?.status !== RECONCILED_BY_DEPARTMENTS
          ? RECONCILED_BY_DEPARTMENTS
          : RECONCILED;
      if (
        this.isVisibleForHostName &&
        status === RECONCILED
      ) {
        [5, 10].includes(this.reconciliation?.reportId)
          ? this.initNcaLayer(APPROVED_BY_ADDITIONAL_DEPARTMENTS)
          : this.initNcaLayer(APPROVED_BY_DEPARTMENTS);
      } else {
        this.initNcaLayer(status);
      }
    },
    canApprove() {
      const { REGION, REGION_DEPARTMENTS } = this.roles;

      return (
        (REGION && this.reconciliation?.status === APPROVED_BY_DEPARTMENTS) ||
        (REGION_DEPARTMENTS && this.reconciliation?.status === RECONCILED) ||
        (REGION_DEPARTMENTS &&
          this.reconciliation?.status === APPROVED_BY_ADDITIONAL_DEPARTMENTS) ||
        (REGION && this.reconciliation?.status === RECONCILED_BY_DEPARTMENTS && this.isVisibleForHostName)
      );
    },
    handleApprove() {
      let status = "";

      if ([5, 10].includes(this.reconciliation.reportId)) {
        if (this.roles.REGION_DEPARTMENTS) {
          if (this.reconciliation.status === APPROVED_BY_DEPARTMENTS) {
            status = APPROVED_BY_ADDITIONAL_DEPARTMENTS;
          } else if (
            this.reconciliation.status === APPROVED_BY_ADDITIONAL_DEPARTMENTS
          ) {
            status = APPROVED;
          } else {
            status = this.isVisibleForHostName
              ? APPROVED_BY_ADDITIONAL_DEPARTMENTS
              : APPROVED_BY_DEPARTMENTS;
          }
        }
      } else {
        if (this.isVisibleForHostName) {
          status = this.reconciliation?.status === APPROVED_BY_DEPARTMENTS ? APPROVED : APPROVED_BY_DEPARTMENTS;
        } else {
          status = this.roles.REGION_DEPARTMENTS
            ? APPROVED_BY_DEPARTMENTS
            : APPROVED;
        }
      }

      this.initNcaLayer(status);
    },
    handleSendToReprocess() {
      MonitoringSnpApi.post("/snp/api/v1/reconciliations/", [
        {
          reconciliationId: this.reconciliation.id,
          status: "SEND_TO_REPROCESS",
        },
      ])
        .then((res) => {
          this.reconciliation = res.data[0];
          this.makeToast(
            "success",
            `Новый статус паспорта - ${this.$t(
              `modules.monitoringSnp.labelledStatuses.${this.reconciliation.reportId}.SEND_TO_REPROCESS`
            )}`,
            "Успешно"
          );
        })
        .catch((err) => {
          if (err.response.data.title === "Reconciliation status error") {
            this.makeToast(
              "danger",
              "Статус формы был изменен другим пользователем",
              "Ошибка"
            );
            this.loadInfo();
          } else {
            this.makeToast(
              "danger",
              "Ошибка при согласовании",
              "Ошибка запроса"
            );
          }
        });
    },
    handleSendToApprove() {
      if (
        Object.values(this.controlMap).includes(true) ||
        Object.values(this.controlMap).includes(1)
      ) {
        this.makeToast(
          "danger",
          "Небходимо закрыть все контроли для согласования",
          "Ошибка при согласовании"
        );
      } else if (
        Object.values(this.warningMap).find((item) => item.length > 0)
      ) {
        this.makeToast(
          "danger",
          "Небходимо закрыть все предупреждения для согласования",
          "Ошибка при согласовании"
        );
      } else {
        this.initNcaLayer("SEND_TO_APPROVE");
      }
    },
    checkNumber(value) {
      if (typeof value === "number") {
        return true;
      } else {
        for (let i = 0; i < value.length; i++) {
          if (
            !(value[i] >= "0" && value[i] <= "9") &&
            value[i] !== "." &&
            value[i] !== ","
          ) {
            return false;
          }
        }
      }

      return true;
    },
    handleFormValidation() {
      this.editedItems = [];
      this.globalArrWatcher();
      if (this.editedItems.length) {
        const lastYearArr = [];
        const prevLastYearArr = [];
        for (let i = 0; i < this.editedItems.length; i++) {
          if (this.editedItems[i].reportYear === this.lastYear) {
            const val = this.editedItems[i].value;
            if (val === null) {
              this.editedItems[i].value = ["string", "list"].includes(
                this.editedItems[i].typeField
              )
                ? ""
                : 0;
              lastYearArr.push(this.editedItems[i]);
            } else if (this.editedItems[i].isTypeString) {
              lastYearArr.push(this.editedItems[i]);
            } else if (isNaN(+val)) {
              lastYearArr.push(this.editedItems[i]);
            } else {
              if (this.editedItems[i].typeField === "integer") {
                this.editedItems[i].value = Math.trunc(
                  Number(this.editedItems[i].value)
                );
              } else {
                this.editedItems[i].value = Number(this.editedItems[i].value);
              }
              // this.editedItems[i].value = Number(this.editedItems[i].value)

              lastYearArr.push(this.editedItems[i]);
            }
          } else if (this.editedItems[i].reportYear === this.previousYear) {
            const val = this.editedItems[i].value;
            if (val === null) {
              this.editedItems[i].value = ["string", "list"].includes(
                this.editedItems[i].typeField
              )
                ? ""
                : 0;
              prevLastYearArr.push(this.editedItems[i]);
            } else if (this.editedItems[i].isTypeString) {
              prevLastYearArr.push(this.editedItems[i]);
            } else if (isNaN(+val)) {
              prevLastYearArr.push(this.editedItems[i]);
            } else {
              this.editedItems[i].value = Number(this.editedItems[i].value);
              prevLastYearArr.push(this.editedItems[i]);
            }
          }
        }
        return { lastYearArr, prevLastYearArr };
      }

      return {};
    },
    handleCheckBeforeSave() {
      const { lastYearArr, prevLastYearArr } = this.handleFormValidation();
      if (lastYearArr?.length || prevLastYearArr?.length) {
        prevLastYearArr?.length && this.handleSaveButton(prevLastYearArr);
        lastYearArr?.length && this.handleSaveButton(lastYearArr);
      }
    },
    setSelectedItemsArr(item) {
      this.selectedItemsArr.push(item.columnNumber);
      if (item.parent) {
        const childs = this.globalArr.filter(
          (row) => row.parent_id === item.id
        );
        for (const child of childs) {
          this.setSelectedItemsArr(child);
        }
      }
    },
    spliceSiblingItems(item) {
      const index = this.reportInitItemsArr.findIndex(
        (column) => column === item.columnNumber
      );
      this.reportInitItemsArr.splice(index, 1);
      if (item.parent) {
        const childs = this.globalArr.filter((row) => {
          return (
            row.parent_id === item.id &&
            !this.selectedItemsArr.includes(row.columnNumber)
          );
        });
        for (const child of childs) {
          this.spliceSiblingItems(child);
        }
      }
    },
    spliceOtherItems(item, isFirstParent) {
      if (isFirstParent || !item.pinned) {
        const index = this.reportInitItemsArr.findIndex(
          (column) => column === item.columnNumber
        );
        this.reportInitItemsArr.splice(index, 1);
      }
      if (item.parent) {
        const childs = this.globalArr.filter(
          (row) => row.parent_id === item.id && !row.pinned
        );
        for (const child of childs) {
          this.spliceOtherItems(child);
        }
      }
    },
    handleLoadReportInitItems(selectedItems) {
      const hasCustomChild = this.globalArr.filter(
        (item) => item.id !== this.modalParent.id && item.hasCustomChild
      );
      this.selectedItemsArr = [];
      this.reportInitItemsArr = this.globalArr.map((item) => item.columnNumber);

      for (const row of selectedItems) {
        this.setSelectedItemsArr(row);
      }
      const firstParent = this.globalArr.find(
        (item) => item.id === this.modalParent.id
      );
      this.spliceSiblingItems(firstParent);
      hasCustomChild.forEach((item) => this.spliceOtherItems(item, true));
      this.reportInitItemsArr && this.handleReportInit(this.reportInitItemsArr);
    },
    handleReportInit(arr) {
      const columns = [...new Set(arr)];
      const body = {
        columns,
        report_id: this.currentReportType,
        kato_code: this.currentSnp.code,
        year: this.lastYear,
      };
      MonitoringSnpApi.post("/snp/api/v1/admin/report-init", body)
        .then((res) => console.log("res", res)) //this.handleSaveButton(arr)
        .catch(() => {
          this.makeToast("danger", "Ошибка при выборе объекта", "Ошибка");
        });
    },
    handleReportReset(header_id) {
      const body = {
        header_id,
        report_id: this.currentReportType,
        kato_code: this.currentSnp.code,
        year: this.lastYear,
      };
      MonitoringSnpApi.put("/snp/api/v1/admin/add-pinned", body)
        .then(() => null)
        .catch(() =>
          this.makeToast("danger", "Ошибка при выборе объекта", "Ошибка")
        );
    },
    handleSaveButton(arr) {
      MonitoringSnpApi.post("/snp/api/v1/admin/form-edit", {
        reportId: this.currentReportType,
        katoCode: this.currentSnp.code,
        reportYear: this.lastYear,
        headerIds: arr.reduce(
          (acc, item) => [...acc, item.controlId || item.headerId],
          []
        ),
        values: arr.reduce((acc, item) => [...acc, item.value], []),
      })
        .then((res) => {
          this.makeToast(
            "success",
            "Изменения успешно сохранены",
            "Сохранение"
          );
          this.updateFormProgress();
          // this.prevGlobalArr = this.copyArray(this.globalArr)
          this.loadInfo();
        })
        .catch(() => {
          this.makeToast("danger", "Ошибка при сохранении изменении", "Ошибка");
        })
        .finally(() => {
          this.loading = false;
          this.editedItems = [];
        });
    },
    getComments() {
      MonitoringSnpApi.get(
        `/snp/api/v1/feed/${this.selectedCommentItem.commentFeedId}/`
      )
        .then((res) => {
          this.modalCommentItem = res.data;
          this.commentModalOpen = true;
        })
        .catch(() =>
          this.makeToast(
            "danger",
            "Ошибка при получении комментариев",
            "Ошибка запроса"
          )
        );
    },
    handleOpenCommentModal(item) {
      this.selectedCommentItem = item;
      if (item.commentFeedId) {
        this.getComments();
      } else {
        this.commentModalOpen = true;
      }
    },
    canClearValue(item) {
      return (
        item.params?.editable &&
        ["IN_PROCESS", "SEND_TO_REPROCESS"].includes(
          this.reconciliation?.status
        )
      );
    },
    handleClearValue(item) {
      const code =
        this.currentSnp?.code ||
        this.me.katoCodes[this.me.katoCodes.length - 1];
      const urlParam = `reportId=${
        this.selectedReportType.id
      }&year=${2022}&code=${code}&column=${item.columnNumber}`;
      MonitoringSnpApi.post(`/snp/api/v1/admin/clear-value?${urlParam}`)
        .then((res) => {
          if (res.status === 200) {
            item.year5 = "";
            this.updateFormProgress();
            this.recalculateFields();
          }
        })
        .catch(() => {});
    },
    createCommentFeed() {
      const code = this.currentSnp?.code;
      MonitoringSnpApi.post(
        `/snp/api/v1/feed/passport/${code}_${this.selectedCommentItem.id}/`,
        { comment: this.comment }
      )
        .then((res) => {
          this.selectedCommentItem.commentFeedId = res.data.id;
          this.modalCommentItem = res.data;
          this.comment = "";
          this.handleCloseCommentModal();
        })
        .catch(() => {});
    },
    handleSendCommentModal() {
      if (this.selectedCommentItem.commentFeedId) {
        MonitoringSnpApi.put(
          `/snp/api/v1/feed/${this.selectedCommentItem.commentFeedId}`,
          { comment: this.comment }
        )
          .then((res) => {
            this.modalCommentItem = res.data;
            this.comment = "";
            this.handleCloseCommentModal();
          })
          .catch(() =>
            this.makeToast(
              "danger",
              "Ошибка",
              "Не удалось отправить комментарий"
            )
          );
      } else {
        this.createCommentFeed();
      }
    },
    handleCloseCommentModal() {
      this.commentModalOpen = false;
      this.selectedCommentItem = null;
      this.modalCommentItem = null;
    },
    openParentModal(par) {
      this.selectedChildren = [];
      this.modalParent = { ...par, open: true };
      this.globalArr = this.globalArr.map((item) =>
        item.id === par.id ? { ...item, open: true } : item
      );
    },
    handleSelectedChildren(selected) {
      selected.forEach((field) => {
        const parentId = this.globalArr.find(
          (row) => row.parent_id === field
        ).id;
        const childs = this.globalArr.filter((row) => row.id === parentId)[0];
        const selectedIndex = this.globalArr.findIndex(
          (row) => row.id === childs.id
        );
      });
    },
    openVillagePassportModal() {
      const code =
        this.currentSnp?.code ||
        this.me.katoCodes[this.me.katoCodes.length - 1];
      this.$refs.villagePassportModal.showEvent(code);
    },
    loadHistory() {
      const params = {
        katoCode:
          this.currentSnp?.code ||
          this.me.katoCodes[this.me.katoCodes.length - 1],
        year: this.lastYear,
        reportId: this.selectedReportType?.id || 1,
      };
      this.historyLoading = true;
      MonitoringSnpApi.get(`/snp/api/v1/reconciliations/history`, params)
        .then(({ data }) => {
          this.historyData = data;
          this.historyLoading = false;
        })
        .catch(() => (this.historyLoading = false));
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },
    initNcaLayer(action) {
        const webSocket = new WebSocket("wss://127.0.0.1:13579/");
        const xml =
          '<?xml version="1.0" encoding="UTF-8" ?><test><id>1</id></test>';

      const body = {
          module: 'kz.gov.pki.knca.commonUtils',
          method: 'signXml',
          args: ['PKCS12', 'SIGNATURE', xml, '', ''],
      }

      webSocket.onopen = () => webSocket.send(JSON.stringify(body));

      webSocket.onmessage = event => {
          const res = JSON.parse(event.data);

          if (res !== null && res.code === '200'){
              const signedXml = res.responseObject;

              const data = {
                      statusRequests: [{ reconciliationId: this.reconciliation.id, status: action }],
                      xml: signedXml,
                  }

              MonitoringSnpApi
                  .post('/snp/api/v1/reconciliations/sign', data)
                  .then(res => {
                      this.reconciliation = res.data[0];
                      this.makeToast('success', `Новый статус паспорта - ${this.$t(`modules.monitoringSnp.labelledStatuses.${this.reconciliation.reportId}.${action}`)}`, 'Успешно')
                  })
                  .catch(err => {
                      const errorData = err.response.data;

                      if (errorData.status === 400){
                          if (errorData.title === 'Permission denied'){
                              this.makeToast('danger', 'Не соответствующий ключ ЭЦП', 'Ошибка');
                          } else if (errorData.title === 'Expired date'){
                              this.makeToast('danger', 'Не действующий ключ ЭЦП', 'Ошибка');
                          } else if (errorData.title === 'Invalid certificate') {
                              this.makeToast('danger', 'Не валидный ключ ЭЦП', 'Ошибка');
                          } else if (errorData.title === 'Bad signature') {
                              this.makeToast('danger', 'Отсутствует подпись', 'Ошибка');
                          } else if (errorData.title === 'Reconciliation status error') {
                              this.makeToast('danger', 'Статус формы был изменен другим пользователем', 'Ошибка');
                              this.loadInfo()
                          } else {
                              this.makeToast('danger', 'Не удалось подписать', 'Ошибка');
                          }
                      } else {
                          this.makeToast('danger', 'Не удалось подписать', 'Ошибка')
                      }
                  })
          }

          if (res?.code === '500' && res?.message === 'storage.empty') {
              alert('Хранилище не обнаружено');
          }
      };

      webSocket.onclose = event => {
          if (!event.wasClean) {
              alert('Запустите пожалуйста программу NCALayer');
          } else {
              console.log('Успешно')
          }
      };
    },
    checkSumOfRows() {
      if (this.loadedReportType?.id === 6) {
        this.checkSum([13, 14, 15], [], 8);
      }

      if (this.loadedReportType?.id === 3) {
        this.checkSum([14, 9], [], 29, "isMore");
        this.checkSum([35, 36, 37, 38, 39, 40], [], 30, "isEqual");
      }
    },
    showColumn(index) {
      return !!this.tableFields[index]?.label;
    },
    checkSum(
      addColumnNumbers,
      subtractColumnNumbers,
      totalColumnNumber,
      comparison = "isLess"
    ) {
      const addFields = addColumnNumbers.map((columnNumber) =>
        this.globalArr.findLast((item) => +item.columnNumber === +columnNumber)
      );
      const subtractFields = subtractColumnNumbers.map((columnNumber) =>
        this.globalArr.findLast((item) => +item.columnNumber === +columnNumber)
      );
      const total = this.globalArr.findLast(
        (item) => +item.columnNumber === +totalColumnNumber
      );
      const sum =
        addFields.reduce((a, b) => a + +b?.year5, 0) -
        subtractFields.reduce((a, b) => a + +b?.year5, 0);

      [...addFields, ...subtractFields].forEach(
        (field) => (this.controlMap[this.globalArr.indexOf(field)] = 0)
      );
      if (comparison === "isLess") {
        if (sum > +total?.year5) {
          [...addFields, ...subtractFields, total].forEach(
            (field) => (this.controlMap[this.globalArr.indexOf(field)] = 1)
          );
        }
      } else if (comparison === "isEqual") {
        if (sum !== +total?.year5) {
          [...addFields, ...subtractFields].forEach(
            (field) => (this.controlMap[this.globalArr.indexOf(field)] = 1)
          );
        }
      } else if (comparison === "isMore") {
        if (sum < +total?.year5) {
          [...addFields, ...subtractFields, total].forEach(
            (field) => (this.controlMap[this.globalArr.indexOf(field)] = 1)
          );
        }
      }
    },
  },
  computed: {
    previousYear() {
      const year = this.tableFields[5]?.label ?? this.tableFields[6]?.label;
      return year ?? new Date().getFullYear();
    },
    lastYear() {
      const year = this.tableFields[6]?.label;
      return year ?? new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation-modal {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;

  & > div {
    text-align: center;
  }
}

.filter-container {
  flex-wrap: wrap;
  height: auto;
  gap: 8px;
  padding: 8px 20px;
}

.filter-actions {
  display: flex;
  flex-wrap: nowrap;
  overflow: auto hidden;

  & > button {
    white-space: nowrap;
  }
}

img {
  cursor: pointer;
}

.tooltip-header {
  text-align: start;
  font-size: 14px;
  color: #c0504c;
  margin-bottom: 0;
}

.tooltip-subheader {
  text-align: start;
  font-size: 14px;
}

.tooltip-message {
  text-align: start;
  font-size: 14px;
}

.tooltip-actions {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 8px;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;

  & button {
    width: 46px;
  }

  & span {
    display: inline-block;
    width: 46px;
    background-color: transparent;
    font-size: 12px;
    color: #6087a0;
    text-transform: none;
    border-bottom: 1px solid #6087a0;
    margin-left: 18px;
    cursor: pointer;
  }
}

.spinner {
  padding-top: calc(100vh / 3.3);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef1f8;
}

.table-container .table thead tr:not(.big-count) th {
  padding: 1rem;
}

.table-container .table tbody tr {
  > :first-child {
    min-height: 50px;
  }

  > :not(:first-child) {
    min-width: 200px;
    max-width: 200px;
    min-height: 50px;
    max-height: 50px;
  }

  > td.actions {
    min-width: 50px;
    max-width: 50px;
  }
}

td,
th {
  height: 50px;
}

td {
  text-align: right;
}

.table-foobar {
  background-color: #fff5da !important;
}

.table-buttons {
  width: 310px;
  display: flex;
  justify-content: space-between;
}

.table-container input,
select {
  border: 1px solid #bcd2e0;
  border-radius: 5px;
  width: 100%;
  text-align: right;
  resize: none;
  outline: none;
}

.icon-box {
  width: 27px;
  height: 27px;
  background-color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.edit-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

  > div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    line-height: 1;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.collapse-btn {
  margin-right: 9px;
}

.comment-modal-body {
  .comment-modal-title {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 700;
  }

  .comment-modal-author {
    color: #7c9db3;
    display: flex;
    gap: 13px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .comment-modal-text {
    position: relative;
    margin-top: 4px;
    border: 1px solid #bcd2e0;
    border-radius: 5px;
    padding: 5px 7px 9px 10px;
    color: #1c2a3e;

    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  textarea {
    min-width: 100%;
    background-color: #fff;
    border: 1px solid #bcd2e0;
    font-size: 12px;
    resize: none;
    border-radius: 5px !important;
    margin: 25px 0;
  }
}

.selected-snp {
  margin-left: 20px;
  color: #1c2a3e;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.selected-report {
  margin-left: 20px;
  color: #1c2a3e;
  font-weight: 700;
  font-size: 14px;
}

.input--control-error {
  color: #c0504c !important;
  border: 2px solid #c0504c !important;

  & option {
    color: #1c2a3e;
  }
}

.control-error {
  color: #c0504c !important;
}

.tooltip.b-tooltip-light .tooltip-inner {
  background-color: #fff9eb;
  box-shadow: 7px 7px 7px 0 #00000012;
}

.addObjectBtn {
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  font-size: 0.7777777778rem;
  font-weight: 600;
  color: #2196f3;
  background: transparent;
  cursor: pointer;

  & > i {
    font-size: 0.8888888889rem;
    margin-right: 10px;
  }
}

.info-title {
  padding-top: 15px;
}
</style>

<style lang="scss">
.custom-multiselect {
  //text-align: right;

  .multiselect__option {
    text-align: left;

    &--selected {
      background-color: #b0e0ff80 !important;
    }
  }

  .multiselect__tags .multiselect__placeholder {
    position: absolute;
    left: 10px;
  }
}
</style>
