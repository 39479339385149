<template>
    <div>
        <div class="actions-tab in-tab">
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-text>
                    <div class="info-title">
                        <span>Мониторинг СНП</span>
                    </div>
                    <div class="info-text">
                        <p>Данный модуль предназначен для сбора и анализа данных по формам мониторинга СНП по
                            приказу Первого заместителя Премьер-Министра Республики Казахстан - Министра
                            регионального развития Республики Казахстан от 24 сентября 2013 года № 239/ОД. .</p>
                        <div class="info-subtitle">
                            <span>Реестр</span>
                        </div>
                        <p>Раздел предназначен для ведения реестра по объектам образования и дорогам местного
                            значения и для просмотра самой актуальной информации.</p>
                    </div>
                </b-dropdown-text>
            </b-dropdown>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div @click="downloadFile">
                            <i class="icon icon-download" style="color: #01AC50; margin-left: 9px;"></i>
                            <span style="margin-right: 15px;">Скачать</span>
                        </div>
                        <i class="icon icon-keyboard"></i>
                    </div>
                </template>
                <b-dropdown-item @click="downloadFile">Реестр</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"><i class="icon icon-filter"></i>Фильтр</span>
                    <span class="rc"><i class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <div class="filter-block">
                        <b-form-group :label="sgpDoc[0].name_ru">
                            <multiselect
                                v-model="templateVal"
                                :options="templateArr.map(temp => temp.name)"
                                placeholder="Выберите шаблон"
                                :show-labels="false"
                                @input="handleTemplateSelect"
                            />
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group :label="sgpDoc[1].name_ru">
                            <multiselect
                                v-model="registryVal"
                                :options="registryArr"
                                placeholder="Выберите реестр"
                                :allow-empty="false"
                                :show-labels="false"
                                label="name"
                                @input="handleRegistrySelect"
                            />
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group :label="sgpDoc[5].name_ru">
                            <multiselect
                                v-model="yearVal"
                                :options="yearsArr"
                                placeholder="Выберите год"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="handleYearSelect"
                            />
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-form-group :label="sgpDoc[2].name_ru">
                            <multiselect
                                v-model="regionVal"
                                :options="regions.filter(item => item.nameRu !== 'Все')"
                                placeholder="Выберите район"
                                :allow-empty="false"
                                :show-labels="false"
                                label="nameRu"
                                @input="handleRegionSelect"
                            />
                        </b-form-group>
                    </div>
                    <template v-if="registryVal && registryVal.id !== 13">
                        <div class="filter-block">
                            <b-form-group :label="sgpDoc[3].name_ru">
                                <multiselect
                                    v-model="districtVal"
                                    :options="districtArr"
                                    placeholder="Выберите сельский округ"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    label="nameRu"
                                    @input="handleDistrictSelect"
                                />
                            </b-form-group>
                        </div>
                        <div class="filter-block" v-if="registryVal && registryVal.id !== 13">
                            <b-form-group :label="sgpDoc[4].name_ru">
                                <multiselect
                                    v-model="snpVal"
                                    :options="districtVal ? snpArr : []"
                                    placeholder="Выберите СНП"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    label="nameRu"
                                    @input="handleSnpSelect"
                                />
                            </b-form-group>
                        </div>
                    </template>
                </div>
                <div class="filter-block" style="margin-top: 30px;">
                    <b-button variant="primary" @click="applyFilter">Применить</b-button>
                    <b-button variant="secondary" @click="resetFilter">Сбросить</b-button>
                </div>
            </b-dropdown>
            <div class="selected-snp" style="flex: 1;" >
                <span v-if="selectedRegionLabel">{{selectedRegionLabel}}</span>
                <span v-else>{{selectedSnpLabel}}</span>
                <template v-if="reconciliation && reconciliation.status">
                    <div
                        v-if="reconciliation.status === 'IN_PROCESS'"
                        class="authorHistory__status ml-4"
                        :class="reconciliation.status"
                    >
                        <span>{{ $t(`modules.monitoringSnp.statuses.${reconciliation.status}`) }}</span>
                        <span>{{ ` - ${progress}%` }}</span>
                    </div>
                    <b-dropdown
                        v-else
                        class="authorHistory--passport"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template #button-content>
                          <span class="authorHistory__status ml-4" :class="reconciliation.status" @click="loadHistory">
                            <span>{{ $t(`modules.monitoringSnp.statuses.${reconciliation.status}`) }}</span>
                            <span v-if="reconciliation.status === 'SEND_TO_REPROCESS'">
                              {{ ` - ${progress}%` }}
                            </span>
                          </span>
                        </template>
                        <div>
                            <b-spinner v-if="historyLoading" small label="Small Spinner"></b-spinner>
                            <template v-else>
                                <div class="authorHistory__list" v-if="historyData.length > 0">
                                    <div v-for="item of historyData" class="row authorHistory__item" :key="item.id">
                                        <div class="col-5 authorHistory__status" :class="item.toStatus">
                                            {{ $t(`modules.monitoringSnp.shortStatuses.${item.toStatus}`) }}
                                        </div>
                                        <div class="col-3">{{ item.createdBy }}</div>
                                        <div class="col-4">{{ formatDate(item.createdAt) }}</div>
                                    </div>
                                </div>
                                <div v-else class="authorHistory__notFound">
                                    История не найдена
                                </div>
                            </template>
                        </div>
                    </b-dropdown>
                </template>
            </div>
            <div class="filter-actions">
                <!-- <b-button
                    v-if="roles.DISTRICT_DEPARTMENTS || roles.ADMIN"
                    variant="success"
                    @click="handleSave"
                    :disabled="!canSaveOrAdd()"
                >
                    Сохранить
                </b-button> -->
                <b-button
                    v-if="roles.ADMIN || (+selectedRegistry.id === 12 && roles.VILLAGE) ||
                        (+selectedRegistry.id === 13 && roles.DISTRICT_DEPARTMENTS)"
                    variant="primary"
                    :disabled="!canSendToApprove()"
                    @click="sendToApprove"
                >
                    На согласование
                </b-button>
                <b-button
                    v-if="(+selectedRegistry.id === 12 && roles.DISTRICT_DEPARTMENTS) || roles.DISTRICT || roles.ADMIN"
                    variant="success"
                    :disabled="!canReconcile()"
                    @click="reconcile"
                >
                    Согласовать
                </b-button>
                <b-button
                    v-if="roles.REGION_DEPARTMENTS || roles.REGION || roles.ADMIN"
                    variant="success"
                    :disabled="!canApprove()"
                    @click="approve"
                >
                    Утвердить
                </b-button>
                <b-button
                    v-if="roles.DISTRICT_DEPARTMENTS || roles.REGION_DEPARTMENTS || roles.REGION || roles.DISTRICT ||
                        roles.ADMIN || (+selectedRegistry.id === 12 && roles.VILLAGE)"
                    variant="secondary"
                    :disabled="!canSendToReprocess()"
                    @click="sendToReprocess"
                >
                    На доработку
                </b-button>
                <!-- <b-button variant="primary" @click="unloadRegistry">Выгрузить</b-button> -->
                <b-button
                    variant="success"
                    @click="createRegistry()"
                    v-if="roles.ADMIN || (+selectedRegistry.id === 12 && roles.VILLAGE) || (+selectedRegistry.id === 13 && roles.DISTRICT_DEPARTMENTS)"
                    :disabled="!canSaveOrAdd()"
                >
                    <i class="icon icon-plus-circle"></i> Добавить
                </b-button>
                <!-- <b-button variant="success" @click="handleSaveTemplate()">Сохранить шаблон</b-button> -->
            </div>
        </div>
        <div class="table-container" v-if="!loading">
            <div class="b-table-sticky-header table-responsive-true">
                <table class="table b-table table-bordered b-table-no-border-collapse constructor-table">
                    <thead>
                        <tr class="text-center" v-if="selectedRegistry">
                            <th></th>
                            <th>{{ selectedRegistry.name }}</th>
                            <th v-if="selectedRegistry.name === 'Реестр школ'">СНП</th>
                            <template v-for="label of [0, 1, 2, 3, 4]">
                                <th :key="label" :class="`labelItem_${label} cursor-point`" @click="reportTypeModal(label)"
                                    v-if="currentHeads[label] === undefined">
                                    Показатель <i class="text-right icon icon-keyboard icon-rotate-90"></i>
                                </th>
                                <th :key="label" :class="`labelItem_${label} cursor-point`" @click="reportTypeModal(label)" v-else>
                                    {{ currentHeads[label].desc }} <i
                                    class="text-right icon icon-keyboard icon-rotate-90"></i>
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="item of globalArr">
                            <tr :key="item.id">
                                <td></td>
                                <td>
                                    <a href="#/monitoring-snp" @click="getSchoolInfo(item, isAfterSelectedTemp)">{{ item.name || 'Имя школы' }}</a>
                                </td>
                                <td v-if="selectedRegistry && selectedRegistry.name === 'Реестр школ'">{{ item.snp }}</td>
                                <template v-for="label of [0, 1, 2, 3, 4]">
                                    <td :key="label" :style="{backgroundColor: '#fff5da'}"
                                        v-if="item.fields[label] !== undefined">
                                        <template>
                                            {{item.fields[label].value}}
                                        </template>
                                    </td>
                                    <th :key="label" :style="{backgroundColor: '#fff5da'}" v-else>
                                    </th>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else class="spinner">
            <b-spinner variant="info" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <b-modal
            id="registry-field"
            v-model="createModalVisible"
            size="lg"
            title="Карточка объекта"
            centered
            content-class="modal-forms"
            body-class="constructor-field"
        >
            <template>
                <b-form inline class="registry-create" id="frm" ref="createRegistryForm">
                    <template v-for="(info, index) in formRegistry">
                        <b-input-group class="mb-2" :class="info.description !== 'Район' && info.description !== 'СНП' && 'no-wrap'" style="width: 100%;" :key="info.column_number + ' ' + index + ' ' + info.id" v-if="notVisible.indexOf(info.description) === -1 && info.description !== 'Сельский округ' && info.description !== 'Область'">
                            <template v-if="info.description === 'Район'">
                                <label class="mr-sm-2">{{ info.description }}</label>
                                    <div style="padding-bottom: 13px">
                                        <multiselect
                                            v-model="createRegionVal"
                                            :options="regions.filter(item => item.nameRu !== 'Все' && regionsKatoCodes.includes(String(item.code)))"
                                            placeholder="Выберите район"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            label="nameRu"
                                            :name="`item_field_${info.id}`"
                                            :id="`item_field_${info.id}`"
                                            @input="handleFromRegionSelect"
                                            :disabled="true"
                                        />
                                    </div>
                                    <b-input-group
                                        class="mb-2"
                                        style="width: 100%;"
                                        v-if="registryVal && registryVal.id !== 13"
                                    >
                                        <label class="mr-sm-2">Сельский округ</label>
                                        <div>
                                            <multiselect
                                                v-model="createDistrictVal"
                                                :options="createDistrictArr.filter(item => item.nameRu !== 'Все')"
                                                placeholder="Выберите сельский округ"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                label="nameRu"
                                                name="districtName"
                                                id="districtId"
                                                @input="handleFromDistrictSelect"
                                                :disabled="!canSaveOrAdd()"
                                            />
                                        </div>
                                    </b-input-group>
                            </template>
                            <template v-else-if="info.description === 'СНП'">
                                <label class="mr-sm-2">{{ info.description }}</label>
                                <div>
                                    <multiselect
                                        v-model="createSnpVal"
                                        :options="createSnpArr.filter(item => item.nameRu !== 'Все')"
                                        placeholder="Выберите СНП"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        :id="`item_field_${info.id}`"
                                        :name="`item_field_${info.id}`"
                                        label="nameRu"
                                        :disabled="!canSaveOrAdd()"
                                    />
                                </div>
                            </template>
                            <template v-else>
                                <label class="mr-sm-2">{{ info.description }}</label>
                                <template v-if="info.params && !info.params.editable && !info.isList">
                                    <div class="px-2">
                                        {{info.value}}
                                    </div>
                                </template>
                                <template v-else-if="info.params && info.isList">
                                        <div>
                                            <multiselect
                                                v-model="info.value"
                                                :options="info.params.values.map(item => ({value: item}))"
                                                placeholder="Выберите"
                                                :allow-empty="info.params.multiple_choice"
                                                :show-labels="false"
                                                :id="`item_field_${info.column_number}`"
                                                :name="`item_field_${info.column_number}`"
                                                label="value"
                                                :disabled="!canSaveOrAdd()"
                                                :multiple="info.params.multiple_choice"
                                                :close-on-select="!info.params.multiple_choice"
                                                track-by="value"
                                                @input="refresh()">
                                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="info.params.multiple_choice && values.length" v-show="!isOpen">{{ values.map(item => item.value).filter(item => !!item).join(', ') }} </span></template>
                                            </multiselect>
                                        </div>
                                </template>
                                <template v-else>
                                        <b-form-input
                                            class="mr-sm-8" size="lg"
                                            :type="info.params && info.params.data_type && (info.params.data_type === 'integer' || info.params.data_type === 'float') ? 'number' : 'text'"
                                            :id="`item_field_${info.columnNumber}`"
                                            :name="`item_field_${info.columnNumber}`"
                                            v-model="info.value"
                                            :disabled="!canSaveOrAdd()"
                                            @input="handleChangeValue(info, index, 'create')"
                                            :class="info.params && controlMap[index] && 'control-error'"
                                            @keypress="e => info.params && info.params.data_type && info.params.data_type === 'integer' && (e.key === '.' || e.key === '-') && e.preventDefault()"
                                            @mousewheel="e => e.preventDefault()"
                                        />
                                    </template>
                            </template>
                        </b-input-group>
                    </template>
                </b-form>
            </template>
            <template #modal-footer style="justify-content: left;">
                <b-button variant="primary" class="float-left" @click="saveRegistry">Сохранить</b-button>
                <b-button variant="light" @click="createModalVisible = false" >Отменить</b-button>
            </template>
        </b-modal>

        <b-modal
            id="registry-field"
            v-model="editModalVisible"
            size="lg"
            title="Карточка объекта"
            centered
            content-class="modal-forms"
            body-class="constructor-field"
        >
            <template>
                <b-form v-if="selectedSchool" inline class="registry-create" id="editFrm" ref="editRegistryForm">
                    <template v-for="(info, index) of selectedSchool.fields">
                        <b-input-group class="mb-2" style="width: 100%;" :class="info.head !== 'Район' && info.head !== 'СНП' && 'no-wrap'" :key="info.column_number + ' ' + index" v-if="info.head !== 'Сельский округ'">
                            <template v-if="info.head === 'Район'">
                                <label class="mr-sm-2">{{ info.head }}</label>
                                    <div style="padding-bottom: 13px">
                                        <multiselect
                                            v-model="editRegionVal"
                                            :options="regions.filter(item => item.nameRu !== 'Все' && regionsKatoCodes.includes(String(item.code)))"
                                            placeholder="Выберите район"
                                            :allow-empty="false"
                                            :show-labels="false"
                                            label="nameRu"
                                            :name="`item_field_${info.column_number}`"
                                            :id="`item_field_${info.column_number}`"
                                            @input="handleFromRegionSelect"
                                            :disabled="true"
                                        />
                                    </div>
                                    <b-input-group
                                        class="mb-2"
                                        style="width: 100%;"
                                        v-if="registryVal && registryVal.id !== 13"
                                    >
                                        <label class="mr-sm-2">Сельский округ</label>
                                        <div>
                                            <multiselect
                                                v-model="editDistrictVal"
                                                :options="editDistrictArr.filter(item => item.nameRu !== 'Все')"
                                                placeholder="Выберите сельский округ"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                label="nameRu"
                                                name="districtName"
                                                id="districtId"
                                                @input="handleFromEditDistrictSelect"
                                                :disabled="!canSaveOrAdd()"
                                            />
                                        </div>
                                    </b-input-group>
                            </template>
                            <template v-else-if="info.head === 'СНП'">
                                <label class="mr-sm-2">{{ info.head }}</label>
                                <div>
                                    <multiselect
                                        v-model="editSnpVal"
                                        :options="createSnpArr.filter(item => item.nameRu !== 'Все')"
                                        placeholder="Выберите СНП"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        :id="`item_field_${info.column_number}`"
                                        :name="`item_field_${info.column_number}`"
                                        label="nameRu"
                                        :disabled="!canSaveOrAdd()"
                                    />
                                </div>
                            </template>
                            <template v-else-if="info.head !== 'Область' && info.head !== 'КАТО'">
                                    <label class="mr-sm-2">{{ info.head }}</label>
                                    <!-- <b-form-input v-if="info.head === 'Район'" disabled class="mr-sm-8" size="lg" type="text"
                                                v-model="info.value"/> -->
                                    <template v-if="info.params && !info.params.editable && !info.isList">
                                        <div class="px-2">
                                            {{info.value}}
                                        </div>
                                    </template>
                                    <template v-else-if="info.params && info.isList">
                                        <div>
                                            <multiselect
                                                v-model="info.value"
                                                :options="info.params.values.map(item => ({value: item}))"
                                                :placeholder="info.head"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                :id="`item_field_${info.column_number}`"
                                                :name="`item_field_${info.column_number}`"
                                                label="value"
                                                :disabled="!canSaveOrAdd()"
                                                :multiple="info.params.multiple_choice"
                                                :close-on-select="!info.params.multiple_choice"
                                                track-by="value">
                                                <template slot="selection" slot-scope="{ values, isOpen }"><span class="multiselect__single" v-if="info.params.multiple_choice && values.length" v-show="!isOpen">{{ values.map(item => item.value).join(', ') }} </span></template>
                                            </multiselect>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <b-form-input v-if="!isSchoolEdit" class="mr-sm-8" size="lg"
                                                :type="info.params && info.params.data_type && (info.params.data_type === 'integer' || info.params.data_type === 'float') ? 'number' : 'text'"
                                                :id="`item_field_${info.column_number}`"
                                                :name="`item_field_${info.column_number}`"
                                                v-model="info.value"
                                                :disabled="!canSaveOrAdd()"
                                                @input="handleChangeValue(info, index)"
                                                :class="info.params && controlMap[index] && 'control-error'"
                                                @keypress="e => info.params && info.params.data_type && info.params.data_type === 'integer' && (e.key === '.' || e.key === '-') && e.preventDefault()"
                                                @mousewheel="e => e.preventDefault()"
                                        />
                                        <b-form-input v-else
                                                    class="mr-sm-8" size="lg"
                                                    :type="info.params && info.params.data_type && info.params.data_type === 'string' ? 'text' : 'number'"
                                                    :id="`item_field_${info.column_number}`"
                                                    :name="`item_field_${info.column_number}`"
                                                    v-model="info.value"
                                                    :disabled="!canSaveOrAdd()"
                                                    @input="handleChangeValue(info, index)"
                                                    :class="info.params && controlMap[index] && 'control-error'"
                                                    @keypress="e => info.params && info.params.data_type && info.params.data_type === 'integer' && (e.key === '.' || e.key === '-') && e.preventDefault()"
                                                    @mousewheel="e => e.preventDefault()"
                                        />
                                    </template>
                            </template>
                        </b-input-group>
                    </template>
                </b-form>
            </template>
            <template #modal-footer style="justify-content: left;" >
                <template v-if="(+selectedRegistry.id === 12 && roles.VILLAGE) || (+selectedRegistry.id === 13 && roles.DISTRICT_DEPARTMENTS)">
                    <b-button variant="danger" class="float-left" @click="openDeleteModal">Удалить</b-button>
                    <b-button variant="primary" class="float-left" @click="editRegistry">Сохранить</b-button>
                    <b-button variant="light" @click="editModalVisible = false">Отменить</b-button>
                </template>
            </template>
        </b-modal>

        <b-modal
            id="registry-field"
            class="myModalProject"
            v-model="modalRegistryVisible"
            size="lg"
            centered
            content-class="modal-forms"
            hide-footer
            body-class="registry-field"
        >
            <template #modal-title>
                Выберите показатель:
                <div style="padding: 7px 0px;">

                    <!-- @blur="searchHeaderText(searchHeader)" -->
                    <b-form-input type="search" size="lg" v-model="searchHeader" placeholder="Поиск" class="mw-100">
                        <i class="icon icon-search"></i>
                    </b-form-input>
                </div>
            </template>
            <template v-for="(item, inx) of searchReportHeadersArr">
                <div :key="inx">
                    <ul class="list-group list-group-">
                        <li
                            class="list-group-item"
                            v-if="item.visible === true && item.description !== 'КАТО'"
                            @click="handleHeaderClick(item)"
                        >
                            {{ item.description }}
                        </li>
                    </ul>
                </div>
            </template>
        </b-modal>
        <b-modal
            id="save-template-form"
            v-model="saveTemplateModalVisible"
            size="sm"
            centered
            title="Введите название шаблона"
            hide-footer
            hide-header
        >
            <b-form class="save-template-form">
                <b-form-group
                    label="Введите название шаблона"
                    label-for="template-name-input"
                >
                    <b-form-input size="sm" id="theme" v-model="templateName"></b-form-input>
                </b-form-group>
                <b-button-group>
                    <b-button variant="success" @click="saveTemplate">Сохранить</b-button>
                    <b-button variant="light" @click="saveTemplateModalVisible = false">Отменить</b-button>
                </b-button-group>
            </b-form>
        </b-modal>

       <b-modal
        id="delete-registry"
        v-model="deleteModalVisible"
        size="sm"
        centered
        hide-footer
        hide-header
        title="Все данные и объект удалятся безвозвратно, вы уверены?"
       >
            <div class="delete-modal">
                <p>Все данные и объект удалятся безвозвратно, вы уверены?</p>
                <b-button variant="danger" @click="deleteRegistry">Удалить</b-button>
                <b-button variant="light" @click="deleteModalVisible = false">Отменить</b-button>
            </div>

       </b-modal>
    </div>
</template>

<script>
import { MonitoringSnpApi } from './api/MonitoringSnpApi'
import { isEmpty } from 'lodash'
import moment from "moment";
import { Ax } from "@/utils";
import {
    IN_PROCESS,
    SEND_TO_APPROVE,
    SEND_TO_REPROCESS,
    APPROVED,
    RECONCILED_BY_DEPARTMENTS,
    RECONCILED,
    APPROVED_BY_DEPARTMENTS,
    ROLE_VILLAGE,
    ROLE_REGION_DEPARTMENTS,
    ROLE_DISTRICT_DEPARTMENTS,
    ROLE_DISTRICT,
    ROLE_REGION,
} from '@/modules/monitoring-snp/consts/statuses'

export default {
    name: 'Registry',
    props: ['me', 'regions', 'reportTypes', 'registryArr', 'yearsArr', 'fromPassport'],
    data() {
        return {
            templateVal: null,
            createModalVisible: false,
            saveTemplateModalVisible: false,
            editModalVisible: false,
            templateName: null,
            templateArr: [],
            yearVal: new Date().getFullYear() - 1,
            registryVal: {
                id: 12,
                name: 'Реестр школ'
            },
            roles: {
                VILLAGE: this.checkRole(ROLE_VILLAGE),
                DISTRICT_DEPARTMENTS: this.checkRole(ROLE_DISTRICT_DEPARTMENTS) || this.checkRole('ROLE_usersnp_district_departments'),
                DISTRICT: this.checkRole(ROLE_DISTRICT),
                REGION_DEPARTMENTS: this.checkRole(ROLE_REGION_DEPARTMENTS) || this.checkRole('ROLE_usersnp_region_departments'),
                REGION: this.checkRole(ROLE_REGION),
                ADMIN: this.checkRole('ROLE_SNP_ADMIN'),
            },
            statusColors: {
                'SEND_TO_APPROVE': '#F79647',
                'RECONCILED': '#2196F3',
                'APPROVED': '#01AC50',
                'SEND_TO_REPROCESS': '#C0504C',
                'IN_PROCESS': '#6087A0'
            },
            registryChange: [],
            regionVal: null,
            regionArr: [],
            snpVal: null,
            snpArr: [],
            districtVal: null,
            districtArr: [],
            sgpDoc: [
                {
                    name_ru: 'Шаблон',
                    name_kk: 'Шаблон',
                    name_en: 'Template'
                },
                {
                    name_ru: 'Реестр',
                    name_kk: 'Реест',
                    name_en: 'Registry'
                },
                {
                    name_ru: 'Район',
                    name_kk: 'Райой',
                    name_en: 'District'
                },
                {
                    name_ru: 'Сельский округ',
                    name_kk: 'Сельский округ',
                    name_en: 'Rural district'
                },
                {
                    name_ru: 'СНП',
                    name_kk: 'СНП',
                    name_en: 'SNP'
                },
                {
                    name_ru: 'Год',
                    name_kk: 'Жыл',
                    name_en: 'Year'
                }
            ],
            infoArr: [],
            infoRegistryArr: [],
            registryDataArr: [],
            accidentRateArr: [
                'Да',
                'Нет'
            ],
            formInfo: [
                {
                    head: 'Наименование объекта',
                    value: '',
                    column_number: 4,
                    isField: false
                },
                {
                    head: 'СНП',
                    value: {
                        code: 556067100,
                        codePotenSnp: "1",
                        codeSnp: "2",
                        dateBegin: "1990-01-01",
                        dateEnd: null,
                        id: 11691,
                        nameKz: "Новочерноярка а.",
                        nameRu: "с.новочерноярка",
                        parentId: 11690,
                        type: 3,
                        typeOfSettlement: 2
                    },
                    column_number: 5,
                    isField: false
                },
                {
                    head: 'Год последнего капитального ремонта',
                    value: '',
                    column_number: 7,
                    isField: true
                },
                {
                    head: 'Процент износа здания',
                    value: '',
                    column_number: 8,
                    isField: true
                },
                {
                    head: 'Кол-во мест',
                    value: '',
                    column_number: 9,
                    isField: true
                },
                {
                    head: 'Кол-во обучающихся детей',
                    value: '',
                    column_number: 10,
                    isField: true
                },
                {
                    head: 'Состояние аварийности (Да/Нет)',
                    value: '',
                    column_number: 11,
                    isField: true
                },
                {
                    head: 'в 1-ой смене',
                    value: '',
                    column_number: 12,
                    isField: true
                },
                {
                    head: 'в 2-ой смене',
                    value: '',
                    column_number: 13,
                    isField: true
                },
            ],
            globalArr: [],
            selectedRate: false,
            formFieldArr: [],
            registryTypeArr: [],
            modalRegistryVisible: false,
            reportHeadersArr: {},
            searchReportHeadersArr: {},
            searchHeader: '',
            selectedType: null,
            selectedHeaderIndx: null,
            currentHeads: [],
            loading: false,
            sections: [],
            katoCodeValue: null,
            selectRegistryName: null,
            selectedSchool: {},
            newItem: {},
            createRegionVal: null,
            createSnpArr: [],
            createSnpVal: null,
            createDistrictArr: [],
            createDistrictVal: null,
            modalSnpArr: [],
            modalSnpVal: null,
            isDisabled: false,
            selectedTemplateHeader: [],
            formRegistry: {},
            currentYear: new Date().getFullYear(),
            isSchoolEdit: false,
            notVisible: ['КАТО'
            ],
            isShowCreateBtn: false,
            isAfterSelectedTemp: false,
            localRoads: null,
            selectedSnpLabel: this.me.initialSnp.nameRu,
            selectedRegionLabel: "",
            editDistrictVal: null,
            editDistrictArr: [],
            editRegionVal: null,
            editSnpVal: null,
            tempDistrict: [],
            regionsKatoCodes: [],
            reconciliation: {},
            controlMap: [],
            progress: 0,
            notificationArray: [],
            deleteModalVisible: false,
            selectedRegistry: {id: '12', name: 'Реестр школ'},
            historyLoading: false,
            historyData: [],
        };
    },
    watch: {
        localRoads: function (newName) {
            this.getInfoLocalStorage(newName);
        },
        fromPassport: function () {
            console.log(this.fromPassport, "this.fromPassport")
            this.regionVal = this.fromPassport.selectedRegion;
            this.registryVal = this.fromPassport.selectedReportType;
            this.snpVal = this.fromPassport.selectedSnp;
            this.loadHeaders();
            this.getSnpData(this.registryVal.id, this.regionVal.code);

        },
        searchHeader: function() {
            this.searchHeaderText(this.searchHeader);
        },
    },
    mounted() {
        this.loadTemplates();
        this.getDefaultInfo();
        this.regionsKatoCodes = this.me?.katoCodes?.filter(item => item[4] === '0' && item[5] === '0');
    },
    methods: {
        getInfoLocalStorage(localRoads) {
            if (!isEmpty(localRoads)) {
                this.registryVal.id = 13;
                this.registryVal.name = 'Реестр дорог';
                this.regionVal = localRoads.selectedRegion;
                this.districtVal = this.snpVal = null;
                this.applyFilter();
            }
        },
        getKatoInfoByCode(code) {
            MonitoringSnpApi.get(`/snp/api/v1/admin/address?code=${code}`)
            .then(res => {
                if (res.status === 200) {
                    if (this.districtArr.length === 0) {
                        const region = this.regions.find((item) => item.nameRu === res.data.region);
                         MonitoringSnpApi
                            .get(`/snp/api/v1/admin/dict-kato?parentId=${region.id}`)
                            .then(res => {
                                this.editDistrictArr = res.data;
                                this.editDistrictVal = res.data.find((item) => item.nameRu === res.data.district);
                            })
                            .catch(err => console.log(err));
                    } else {
                        this.editDistrictVal = this.districtArr.find((item) => item.nameRu === res.data.district);
                    }

                    this.editDistrictVal && this.loadAllSnp(this.editDistrictVal.code);
                }
            })
            .catch(err => {
                console.log('err', err)
                    'Ошибка'
            });
        },
        handleYearSelect() {
            const registryId = this.registryVal.id;
            MonitoringSnpApi.get(`/snp/api/v1/registry/data?type=${registryId}&year=${this.yearVal}`)
                .then(res => {
                    if (res.status === 200) {
                        this.registryDataArr.push(...res.data);
                    }
                })
                .catch(err => {
                    console.log('err', err)
                })
                .finally(() => this.loading = false)
        },
        handleTemplateSelect() {
            this.regionVal = null;
            this.districtVal = null;
            this.snpVal = null;
        },
        handleRegistrySelect() {
            if (this.registryVal !== null) {
                this.templateVal = null;
                this.formRegistry = [];

                this.getRegistryForm();
            }
        },
        searchHeaderText(searchText) {
            if (!isEmpty(searchText)) {
                const arrFilter = this.reportHeadersArr;
                const item = arrFilter.filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()));
                this.searchReportHeadersArr = item;
            } else {
                this.searchReportHeadersArr = this.reportHeadersArr;
            }
        },
        handleRegionSelect() {
            this.templateVal = null;
            this.districtVal = null;
            this.snpVal = null;
            if (this.regionVal?.nameRu === 'Все') {
                this.districtArr = [];
                this.snpArr = [];
            } else {
                this.regionVal?.code && this.loadDistricts();
            }
        },
        handleDistrictSelect() {
            this.templateVal = null;
            this.snpVal = null;
            if (this.districtVal?.nameRu === 'Все') {
                this.snpArr = [];
            }
            this.districtVal?.code && this.loadSnp();
        },
        handleSnpSelect() {
           this.setSelectedSnpLabel();
        },
        setSelectedSnpLabel() {
            this.selectedSnpLabel = "";
            if (this.districtVal && this.snpVal?.nameRu === 'Все') {
                this.selectedSnpLabel = this.districtVal.nameRu + ' / ' + this.snpVal.nameRu;
            } else if (this.regionVal && this.districtVal?.nameRu === 'Все') {
                this.selectedSnpLabel = this.regionVal.nameRu + ' / ' + this.districtVal.nameRu;
            } else {
                this.selectedSnpLabel = this.snpVal?.nameRu;
            }
        },
        setSelectedRegionLabel() {
            this.selectedRegionLabel = this.regionVal?.nameRu ?? '';
        },
        checkRole(role) {
            if (this.me?.roles) {
                return this.me.roles.find(r => r.toLocaleLowerCase().startsWith(role.toLocaleLowerCase()));
            }
            return false;
        },
        checkKato() {
            return this.me.katoCodes.includes(this.snpVal?.code.toString());
        },
        checkRoleByCode(code) {
            return this.me.katoCodes.includes(code.toString());
        },
        checkEditAvailable(role) {
            if (this.snpVal?.nameRu === 'Все') {
                return false;
            } else {
                return this.checkRole(role) && this.me.katoCodes.includes(this.snpVal?.code.toString())
            }
        },
        handleUpdateRegistry(value, label) {
            this.updateRegistry(value, value.fields[label].value, label);
        },
        handleFromRegionSelect() {
            this.loadTempDistricts()
            this.createRegionVal && this.loadAllSnp(this.createRegionVal.code);
            this.editRegionVal && this.loadAllSnp(this.editRegionVal.code)
            this.createDistrictVal = null;
            this.editDistrictVal = null;
        },
        handleFromDistrictSelect() {
            this.createDistrictVal && this.loadAllSnp(this.createDistrictVal.code);
        },
        handleFromEditDistrictSelect() {
            this.editSnpVal = null;
            this.editDistrictVal && this.loadAllSnp(this.editDistrictVal.code);
        },
        handleSaveTemplate() {
            if (this.currentHeads.length !== 5) {
                this.makeToast('danger', 'Вы не выбрали все заголовки таблицы', 'Ошибка!!!');
                return;
            }
            this.saveTemplateModalVisible = true;
        },
        getDefaultInfo() {
            this.loading = true;
            MonitoringSnpApi.get(`/snp/api/v1/registry/headers?type=12`)
                .then(res => {
                    this.reportHeadersArr = res.data;
                    this.searchReportHeadersArr = res.data;
                    this.getRegistryForm();
                    this.getInitialSnpData();
                    this.getReconciliation();
                    this.loading = false;
                })
                .catch(err => {
                    console.log('err', err)
                });

            MonitoringSnpApi
                .get(`snp/api/v1/reconciliations/progress-total?katoCode=${this.me.initialSnp.katoCode}&reportId=12`)
                .then(res => this.progress = res.data.average_progress)
        },
        loadHeaders() {
            MonitoringSnpApi
                .get(`/snp/api/v1/registry/headers?type=${this.registryVal.id}`)
                .then(res => {
                    this.reportHeadersArr = res.data;
                    this.searchReportHeadersArr = res.data;
                    this.getRegistryForm()
                })
                .catch(err => {
                    console.log('err', err)
                });
        },
        getRegistryHeaders(typeId) {
                // this.currentHeads = [];
                this.districtArr = [];
                this.snpArr = [];
                // this.regionVal = null;
            MonitoringSnpApi.get(`/snp/api/v1/registry/headers?type=${typeId}`)
                .then(res => {
                    this.reportHeadersArr = res.data;
                    this.searchReportHeadersArr = res.data;
                    this.getRegistryForm();
                })
                .catch(err => {
                    console.log('err', err)
                });
        },
        loadTemplateRegistry() {
            this.regionVal = this.districtVal = this.snpVal = null;
            this.isTemplate = true;
            const selectedTemplateInit = this.templateArr.find(template => template.name === this.templateVal);
            this.tempRegions = selectedTemplateInit.sections[0].first.values;
            if (this.tempRegions === null) {
                this.makeToast('danger', 'Пользователь не прикрпелен к региону', 'Ошибка');
                return;
            }

            this.globalArr = [];
            this.currentHeads = [];
            this.yearHeads = [];

            this.tempRegions.forEach((item, index) => {
                const obj = {
                    idx: index,
                    code: item.address.code,
                    name: item.additional_index,
                    snp: item.address.snp,
                    isEdit: false,
                    fields: []
                };
                this.globalArr.push({ ...obj });
            });

            selectedTemplateInit.sections.forEach((item, sectionIndex) => {
                this.currentHeads[sectionIndex] = {
                    idx: sectionIndex,
                    desc: item.head
                };

                item.first.values.forEach((valItem, valIndex) => {
                    this.globalArr[valIndex].fields.push({ ...valItem });
                });
            });
        },
        loadDistricts() {
            try {
                MonitoringSnpApi
                    .get(`/snp/api/v1/admin/dict-kato?parentId=${this.regionVal?.id}`)
                    .then(res => {
                        this.districtArr = [{ nameRu: 'Все' }, ...res.data];
                        this.createDistrictArr = res.data;
                        this.editDistrictArr = res.data;
                    })
                    .catch(err => console.log(err));
            } catch (error) {
                console.log(error);
            }
        },
        loadTempDistricts(){
            const code = this.createModalVisible ? this.createRegionVal.id : this.editRegionVal.id;

             try {
                MonitoringSnpApi
                    .get(`/snp/api/v1/admin/dict-kato?parentId=${code}`)
                    .then(res => {
                        this.createDistrictArr = res.data;
                        this.editDistrictArr = res.data;
                    })
                    .catch(err => console.log(err));
            } catch (error) {
                console.log(error);
            }
        },
        loadSnp() {
            try {
                MonitoringSnpApi
                    .get(`/snp/api/v1/admin/dict-kato?parentId=${this.districtVal?.id}`)
                    .then(res => {
                        this.snpArr = [{ nameRu: 'Все' }, ...res.data];
                        this.createSnpArr = res.data;
                    })
                    .catch(err => console.log(err));
            } catch (error) {
                console.log(error);
            }
        },
        getChildValueByParentId(parentId) {
            MonitoringSnpApi
                    .get(`/snp/api/v1/admin/dict-kato?parentId=${parentId}`)
                    .then(res => {
                       this.tempDistrict = res.data
                    })
                    .catch(err => console.log(err));
        },
        getCode() {
            const code = {};
            if (this.snpVal?.code) {
                code.katoCode = this.snpVal.code;
            } else if (this.districtVal?.code) {
                code.katoCode = this.districtVal.code;
            } else if (this.regionVal?.code) {
                code.katoCode = this.regionVal.code;
            }

            if (this.registryVal.id === 13 && this.regionVal) {
                code.katoCode = this.regionVal.code;
            }

            return code;
        },
        loadAllSnp(codeKato) {
            try {
                MonitoringSnpApi
                    .get(`/snp/api/v1/admin/all-snp?code=${codeKato}`)
                    .then(res => {
                        this.modalSnpArr = res.data;
                        this.createSnpArr = res.data;
                        if (this.snpVal && this.snpVal.nameRu !== 'Все'){
                            this.createSnpVal = this.snpVal
                        } else if (this.snpVal && this.snpVal.nameRu === 'Все'){
                            this.createSnpVal = res.data[0];
                        } else {
                            this.createSnpVal = res.data.find((item) => item.nameRu === this.me.initialSnp.snpNameRu);
                        }
                        if (this.selectedSchool)
                            this.editSnpVal = this.createSnpArr.find((item) => item.nameRu === this.selectedSchool.address_name);
                    })
                    .catch(err => console.log(err));
            } catch (error) {
                console.log(error);
            }
        },
        closeFilterPanel() {
            this.$refs.drop.hide();
        },
        downloadFile() {

            let url;
            let code;
            if (this.snpVal?.code) {
                code = this.snpVal.code;
                url = `/snp/api/v1/report/file/registry?code=${this.snpVal.code}&registryId=${this.registryVal.id}&year=${this.yearVal}`;
            } else if (this.districtVal?.code) {
                code = this.districtVal.code;
                url = `/snp/api/v1/report/file/registry?code=${this.districtVal.code}&registryId=${this.registryVal.id}&year=${this.yearVal}`;
            } else if (this.regionVal?.code) {
                code = this.regionVal.code;
                url = `/snp/api/v1/report/file/registry?code=${this.regionVal.code}&registryId=${this.registryVal.id}&year=${this.yearVal}`;
            }

            Ax(
                {
                    url,
                    method: 'GET',
                    data: null,
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.token}`,
                    }
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${code}-${this.registryVal.id}-${this.yearVal}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    console.log('err', error);
                }
            );
        },
        applyFilter() {
            this.isAfterSelectedTemp = false;
            if (this.templateVal === null && this.regionVal !== null && this.districtVal !== null && this.snpVal === null) {
                if (this.districtVal.nameRu !== 'Все') {
                    this.makeToast('danger', 'Вы не выбрали СНП', 'Ошибка');
                    return false;
                }
            }
            if (this.templateVal !== null) {
                this.isAfterSelectedTemp = true;
                this.templateVal && this.loadTemplateRegistry();
                this.closeFilterPanel();
                return;
            } else {
                if (this.templateVal === null) {
                    if (this.registryVal === null) {
                        alert('Вы не выбрали Реестр');
                        return;
                    }
                }
                const registryId = this.registryVal.id;
                if (this.regionVal !== null && this.districtVal === null && registryId === 12) {
                    if (this.regionVal.nameRu !== 'Все') {
                        this.makeToast('danger', 'Вы не выбрали Сельский округ', 'Ошибка');
                        return false;
                    }
                }
                if (this.regionVal !== null || this.districtVal !== null || this.snpVal !== null) {
                    this.isTemplate = false;
                }
                this.getRegistryData(registryId);

                const code = this.getCode();
                this.getSnpData(registryId, code.katoCode);
            }
            this.currentHeads = [];
            this.selectedRegistry = this.registryVal;
            this.getReconciliation();
            this.getProgress();
            this.closeFilterPanel();
            this.loadHeaders();
            if (this.registryVal.id === 12){
                this.selectedRegionLabel = null;
                this.setSelectedSnpLabel();
            } else if (this.registryVal.id === 13){
                this.selectedSnpLabel = null;
                this.setSelectedRegionLabel();
            }
        },
        resetFilter() {
            this.templateVal = this.regionVal = this.districtVal = this.snpVal = this.registryVal = this.yearVal = null;
            // this.districtVal = this.snpArr = this.globalArr = [];
            // this.closeFilterPanel();
            // this.currentHeads = [];
        },
        reloadData() {
            this.globalArr = [];
            this.currentHeads = [];
            this.loadSnpData();
        },
        loadTemplateHeader(res, selHead) {
            selHead.forEach((item, index) => {
                const filteredHead = res.filter((data) => {
                    return data.description === item.desc && data.type === item.type
                })[0];
                const selectedHead = {
                    idx: index,
                    id: filteredHead.id,
                    desc: filteredHead.description,
                    type: filteredHead.type,
                    column_number: filteredHead.column_number,
                    parentId: filteredHead.parentId
                };

                this.currentHeads[index] = selectedHead;
                const sectionItem = {
                    head: filteredHead.description,
                    first: {},
                    second: {}
                };
                this.sections[selectedHead.idx] = sectionItem;
            });
        },
        defaultHeader(res) {
            const registryId = this.registryVal.id;
            const defaultHeaders = res.filter((item) => {
                if (registryId === 12) {
                    return item.columnNumber >= 8 && item.columnNumber <= 12;
                }
                if (registryId === 13) {
                    return (item.columnNumber >= 7 && item.columnNumber <= 10) || item.columnNumber === 19;
                }
            });
            defaultHeaders.forEach((item, index) => {
                const selectedHead = {
                    idx: index,
                    id: item.id,
                    desc: item.description,
                    type: item.type,
                    columnNumber: item.columnNumber,
                    parentId: item.parentId
                };
                this.currentHeads[index] = selectedHead;
                const sectionItem = {
                    head: item.description,
                    first: {},
                    second: {}
                };
                this.sections[selectedHead.idx] = sectionItem;
            });
        },
        loadSnpData() {
            this.districtMap = {};
            const code = {};
            const selectedVal = {};
            if (this.snpVal?.code) {
                code.katoCode = this.snpVal.code;
                selectedVal.id = this.snpVal.id;
                this.katoCodeValue = this.snpVal.code;
            } else if (this.districtVal?.code) {
                code.katoCode = this.districtVal.code;
                selectedVal.id = this.districtVal.id;
                this.katoCodeValue = this.districtVal.code;
            } else if (this.regionVal?.code) {
                code.katoCode = this.regionVal.code;
                selectedVal.id = this.regionVal.id;
                this.katoCodeValue = this.regionVal.code;
            }
            this.loading = true;
            selectedVal.id && (
                MonitoringSnpApi
                    .get('/snp/api/v1/admin/dict-kato', { parentId: selectedVal.id })
                    .then(res => {
                        this.infoArr = res.data;
                        this.infoArr.forEach((item) => {
                            if (!this.districtMap.hasOwnProperty(item.nameRu)) {
                                const obj = {
                                    id: item.id,
                                    code: item.code,
                                    name: 'КГУ "СОШ имени Мухтара Ауезова"',
                                    snp: item.nameRu,
                                    fields: {}
                                }
                                this.districtMap[item.id] = obj;
                                this.globalArr.push({ ...obj });
                            }
                        });
                    })
                    .catch(err => console.log(err))
                    .finally(() => this.loading = false)
            );

        },
        reportTypeModal(columnIdx) {
            this.selectedHeaderIndx = columnIdx;
            this.modalRegistryVisible = true;
        },
        handleHeaderClick(header) {
            const selectedHead = {
                idx: this.selectedHeaderIndx,
                id: header.id,
                desc: header.description,
                type: header.type,
                columnNumber: header.columnNumber,
                parentId: header.parentId
            };
            this.currentHeads[this.selectedHeaderIndx] = selectedHead;
            const sectionItem = {
                head: header.description,
                first: {},
                second: {}
            };
            this.sections[selectedHead.idx] = sectionItem;
            this.modalRegistryVisible = false;
            this.initSectionValue(this.katoCodeValue);
        },
        getInitialSnpData() {
            const code = {};
            this.regionVal = this.regions.find(reg => reg.code === this.me.initialSnp.regionKatoCode);
            code.katoCode = this.me.katoCodes[this.me.katoCodes.length - 1];
            this.katoCodeValue = this.me.katoCodes[this.me.katoCodes.length - 1];
            //code.katoCode = 556067100;
            //this.katoCodeValue = 556067100;
            const fieldObj = {
                column_number: null,
                editable: null,
                head: null,
                params: null,
                value: null,
                isEdit: false
            };
            const fieldsObj = [];
            fieldsObj.push(fieldObj);
            fieldsObj.push(fieldObj);
            fieldsObj.push(fieldObj);
            fieldsObj.push(fieldObj);
            fieldsObj.push(fieldObj);

            this.districtMap = {};

            const kato = this.me.initialSnp.katoCode || code.katoCode;
            this.globalArr = [];
            MonitoringSnpApi.get(`/snp/api/v1/registry/settlement/data?type=12&code=${kato}&year=${this.yearVal}`)
                .then(res => {
                    if (res.status === 200) {
                        this.infoRegistryArr = res.data;
                        this.infoRegistryArr.forEach((item) => {
                            if (!this.districtMap.hasOwnProperty(item.name)) {
                                const obj = {
                                    id: item.id,
                                    code: item.code,
                                    name: item.name,
                                    snp: item.address_name,
                                    type: item.type,
                                    address_name: item.address_name,
                                    "additional_index": item.additional_index,
                                    year: item.year,
                                    fields: fieldsObj,
                                    region: item.region,
                                    region_code: item.region_code,
                                    district: item.district,
                                    district_code: item.district_code
                                }
                                this.districtMap[item.id] = obj;
                                this.globalArr.push({ ...obj });
                                this.defaultHeader(this.reportHeadersArr);
                                this.initSectionValue();
                            }
                        });

                        MonitoringSnpApi
                            .get(`/snp/api/v1/admin/dict-kato?parentId=${this.regionVal?.id}`)
                            .then(res => {
                                this.districtArr = [{ nameRu: 'Все' }, ...res.data];
                                this.createDistrictArr = res.data;
                                this.editDistrictArr = res.data;
                                this.districtVal = this.districtArr.find(dist => dist.code === this.me.initialSnp.districtKatoCode);
                                this.createDistrictVal = this.districtVal;
                                MonitoringSnpApi
                                    .get('/snp/api/v1/admin/dict-kato', { parentId: this.districtVal?.id })
                                    .then(res => {
                                        this.snpArr = [{nameRu: 'Все'}, ...res.data];
                                        this.snpVal = res.data.find(dist => dist.code === this.me.initialSnp.katoCode);
                                        this.setSelectedSnpLabel();
                                    })
                                    .catch(() => {
                                    });
                            })
                            .catch(err => console.log(err));
                            }
                })
                .catch(err => {
                    console.log('err', err)
                })
                .finally(() => this.loading = false);
        },
        getSnpData(registryId, code, isTemplate = false) {
            this.loading = true;
            const fieldObj = {
                column_number: null,
                editable: null,
                head: null,
                params: null,
                value: null
            };
            const fieldsObj = [];
            fieldsObj.push(fieldObj);
            fieldsObj.push(fieldObj);
            fieldsObj.push(fieldObj);
            fieldsObj.push(fieldObj);
            fieldsObj.push(fieldObj);

            this.districtMap = {};
            this.globalArr = [];

            MonitoringSnpApi.get(`/snp/api/v1/registry/settlement/data?type=${registryId}&code=${code}&year=${this.yearVal}`)
                .then(res => {
                    if (res.status === 200) {
                        this.infoRegistryArr = res.data;
                        this.infoRegistryArr.forEach((item) => {
                            if (!this.districtMap.hasOwnProperty(item.name)) {
                                const obj = {
                                    id: item.id,
                                    code: item.code,
                                    name: item.name,
                                    snp: item.address_name,
                                    type: item.type,
                                    "additional_index": item.additional_index,
                                    year: item.year,
                                    fields: fieldsObj
                                }
                                this.districtMap[item.id] = obj;
                                this.globalArr.push({ ...obj });
                                if (!isTemplate) {
                                    this.defaultHeader(this.reportHeadersArr);
                                } else {
                                    this.loadTemplateHeader(this.reportHeadersArr, this.selectedTemplateHeader);
                                }
                                this.initSectionValue();
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log('err', err)
                })
                .finally(() => this.loading = false)
        },
        getRegistryData(typeId) {
            this.loading = true;
            MonitoringSnpApi.get(`/snp/api/v1/registry/data?type=${typeId}&year=${this.yearVal}`)
                .then(res => {
                    if (res.status === 200) {
                        this.registryDataArr.push(...res.data);
                    }
                })
                .catch(err => {
                    console.log('err', err)
                })
                .finally(() => this.loading = false)
        },
        initSectionValue() {
            this.katoData = {};
            this.globalArr?.forEach((item) => {
                this.katoData = this.infoRegistryArr.find((infoItem) => item.id === infoItem.id);
                if (!isEmpty(this.katoData)) {
                    this.tempFields = [];
                    this.currentHeads?.forEach((head, headIndex) => {
                        const fieldInfo = this.katoData.fields.find((findItem) => findItem.head === head.desc);
                        if (fieldInfo != undefined) {
                            const newField = {
                                idx: headIndex,
                                columnNumber: fieldInfo.column_number,
                                editable: fieldInfo.editable,
                                head: fieldInfo.head,
                                params: fieldInfo.params,
                                value: fieldInfo.value
                            };
                            this.tempFields[headIndex] = newField;
                        }
                    });
                    item.fields = this.tempFields;
                }
            });
        },
        modalUpdateRegistry(item, val, itemId) {
            //this.updateRegistry(item, val, itemId)
            const formInfo = {
                    "id": item.id,
                    "code": parseInt(item.code),
                    "name": item.name,
                    "type": item.type,
                    "year": item.year,
                    "address_name": item.snp,
                    "additional_index": item.additional_index,
                    "fields": [
                        {
                            "value": val.toString(),
                            "editable": item.editable,
                            "params": item.params,
                            "head": item.head,
                            "column_number": item.columnNumber
                        }
                    ]
                };
            const body = [
                {
                    value: val.toString(),
                    editable: item.editable,
                    params: item.params,
                    head: item.head,
                    column_number: item.column_number
                }
            ];
            // MonitoringSnpApi.put(`/snp/api/v1/registry/update/${itemId}`, body)
            //     .then(res => {
            //         if (res.status === 200) {
            //         }
            //     })
            //     .catch(err => console.log(err));
        },
        updateRegistry(item, val, label) {
            const registryItem = this.infoRegistryArr.find((i) => i.id === item.id);
            const field = registryItem.fields.find((i) => i.column_number == item.fields[label].columnNumber);

            field.value = val.toString();

            // const formInfo = {
            //         "id": item.id,
            //         "code": parseInt(item.code),
            //         "name": item.name,
            //         "type": item.type,
            //         "year": item.year,
            //         "address_name": item.snp,
            //         "additional_index": item.additional_index,
            //         "fields": [
            //             {
            //                 "value": val.toString(),
            //                 "editable": item.fields[label].editable,
            //                 "params": item.fields[label].params,
            //                 "head": item.fields[label].head,
            //                 "column_number": item.fields[label].columnNumber
            //             }
            //         ]
            //     };
            // console.log('formInfo', formInfo);
            MonitoringSnpApi.put(`/snp/api/v1/registry/update`, registryItem)
                .then(res => {
                    if (res.status === 200) {
                        const data = res.data.fields.find((i) => i.column_number === item.fields[label].columnNumber);
                        item.fields[label].value = data.value;
                        item.fields[label].isEdit = true;
                        const infoRegistryItem = this.infoRegistryArr.find((i) => i.id === item.id);
                        const infoRegistryField = infoRegistryItem.fields.find((i) => i.column_number === item.fields[label].columnNumber);
                        infoRegistryField.value = data.value;
                    }
                })
                .catch(() => {
                    this.makeToast('danger', 'Не удалось обновить данные', 'Ошибка');
                    return;
                })
        },
        makeToast(variant = null, toastBody, title) {
            this.$bvToast.toast(toastBody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            });
        },
        setRegion(region) {
            this.editRegionVal = region;
            if (this.editDistrictVal.id) {
                MonitoringSnpApi
                    .get(`/snp/api/v1/admin/dict-kato?parentId=${this.editDistrictVal.id}`)
                    .then(res => {
                      this.createSnpArr = res.data;
                      this.editSnpVal = this.createSnpArr.find((item) => item.nameRu === this.selectedSchool.address_name);
                    })
                    .catch(() => null);
            }
        },
        getSchoolInfo(data, isAfterSelectedTemp) {
            this.selectedSchool = {};
            this.controlMap = [];
            if (!isAfterSelectedTemp) {
                const selected = this.infoRegistryArr.find((item) => item.id === data.id);
                this.selectedSchool = {
                    ...selected,
                    fields: selected.fields.map(item => {
                        const elem = this.reportHeadersArr.find(elem => item.column_number === elem.columnNumber);
                        const params = elem && elem.params && JSON.parse(elem.params);
                        const isList = params && params.values && params.values.every(item => !item.startsWith('id_')) && params.values.filter(item => ['+', '-', '/', '*', '<', '>', '=', '<=', '>='].includes(item)).length === 0;
                        const editable = params && params.editable;
                        let value = item.value;

                        if (params){
                            if (value && value.length){
                                if (params.multiple_choice){
                                    value = item.value.split(', ').map(item => item.length && ({value: item})) || [];
                                } else if (isList){
                                    value = {value: item.value}
                                }
                            } else {
                                value = null;
                            }
                        }

                        return {
                            ...item,
                            ...elem,
                            params,
                            isList,
                            value,
                            editable,
                        }
                    })
                };

                if (!isEmpty(this.selectedSchool)) {
                    const temp = [];
                    this.formRegistry.forEach(element => {
                        const elem = this.selectedSchool.fields.find(x => x.head === element.description);
                        if (elem.column_number === 6 && elem.value === null) {
                            elem.value = this.selectedSchool.name;
                        }
                        if (!isEmpty(elem)) {
                            temp.push({...elem});
                        }
                    });
                    this.selectedSchool.fields = temp;

                    this.isSchoolEdit = this.checkIsSchoolEdit(this.selectedSchool.code, 'ROLE_SNP_VILLAGE')
                        || this.checkIsSchoolEdit(this.selectedSchool.code, 'ROLE_SNP_DISTRICT');

                    const region = this.regions.find((item) => item.code.toString() === this.selectedSchool.region_code);
                    MonitoringSnpApi
                        .get(`/snp/api/v1/admin/dict-kato?parentId=${region.id}`)
                        .then(result => {
                          this.editDistrictArr = result.data;
                          this.editDistrictVal = this.editDistrictArr.find((item) => item.code.toString() === this.selectedSchool.district_code);
                          this.setRegion(region);
                        })
                        .catch(() => null);
                    this.editModalVisible = true;
                }
            }

            if (isAfterSelectedTemp) {
                const registryId = this.registryVal.id;
                MonitoringSnpApi.get(`/snp/api/v1/registry/settlement/data?type=${registryId}&code=${data.code}&year=${this.yearVal}`)
                    .then(res => {
                        if (res.status === 200) {
                            this.infoRegistryArr = res.data;
                            const selected = res.data.find((item) => item.name === data.name);
                            this.selectedSchool = {...selected};

                            if (!isEmpty(this.selectedSchool)) {
                                this.isSchoolEdit = this.checkIsSchoolEdit(this.selectedSchool.code, 'ROLE_SNP_VILLAGE')
                                    || this.checkIsSchoolEdit(this.selectedSchool.code, 'ROLE_SNP_DISTRICT');
                                this.editModalVisible = true;
                                this.getKatoInfoByCode(this.selectedSchool.code);
                            }
                        }
                    })
                    .catch(() => this.loading = false)
                    .finally(() => this.loading = false)
            }
        },
        checkIsSchoolEdit(code, role) {
            return this.checkRole(role) && this.me.katoCodes.includes(code.toString());
        },
        resetFrom() {
            const self = this;
            if (this.formRegistry !== undefined) {
                Object.keys(this.formRegistry).forEach(function (key) {
                    if (self.formRegistry[key].description !== 'Район' && self.formRegistry[key].description !== 'СНП') {
                        self.formRegistry[key].value = '';
                    }
                });
            }
        },
        createRegistry() {
            this.resetFrom();
            this.controlMap = [];
            if (this.me.initialSnp === undefined || this.me.initialSnp === null) {
                this.makeToast('danger', 'Пользователь не прикрпелен к региону', 'Ошибка');
                return;
            }
            this.loading = true;
            this.newItem = {};
            this.createRegionVal = this.regionVal;
            this.createDistrictVal = this.districtVal;
            this.createSnpVal = this.snpVal;
            if (!isEmpty(this.createDistrictVal)) {
                this.loadAllSnp(this.createDistrictVal.code);
            } else {
                this.loadAllSnp(this.createRegionVal.code);
            }

            setTimeout(() => {
                this.createModalVisible = true;
                this.loading = false;
            }, 2000);
        },
        editRegistry() {
            if (this.controlMap.some(item => Boolean(item))){
                [...new Set(this.notificationArray.map(({type, msg, title}) => JSON.stringify({type, msg, title})))].map(JSON.parse).forEach(({type, msg, title}) => this.makeToast(type, msg, title));
                return;
            }

            const registryId = this.registryVal.id;
            const myForm = document.getElementById('editFrm');
            const formData = new FormData(myForm);
            const formValue = {};

            for (const [key, val] of formData.entries()) {
                Object.assign(formValue, { [key]: val })
            }

            this.selectedSchool.fields.forEach((item) => {
                if (item.value){
                    if (item.column_number === 5 && registryId === 12) {
                        item.value = this.editRegionVal?.nameRu ?? item.value;
                    } else if (item.column_number === 3 && registryId === 13) {
                        item.value = this.editRegionVal?.nameRu.split(' ')[0] ?? item.value;
                    } else if (!isNaN(item.value) && (item.params?.data_type === 'integer')){
                        item.value = +item.value;
                    } else if (!isNaN(item.value) && item.params?.data_type === 'float'){
                        item.value = +((+item.value).toFixed(2));
                    } else if (item.params && item.params.multiple_choice){
                        item.value = item.value.map(elem => elem.value).join(', ') ?? "";
                    } else if (item.isList) {
                        item.value = item.value?.value;
                    }
                } else {
                    if (item.params && item.params.multiple_choice){
                        item.value = item.value?.length ? item.value.map(elem => elem.value).join(', ') : "";
                    } else {
                        item.value = null;
                    }
                }
            });

            const editInfo = {
                id: this.selectedSchool.id,
                code: (this.selectedSchool.type === 12 ? this.editSnpVal?.code : this.editRegionVal?.code) ||
                    this.selectedSchool.code,
                name: (this.selectedSchool.type === 12 ? formValue['item_field_3'] : formValue['item_field_6']) ||
                    this.selectedSchool.name,
                type: this.selectedSchool.type,
                year: this.selectedSchool.year,
                fields: this.selectedSchool.fields.map(({value, editable, params, head, column_number}) => ({value, editable, head, column_number, params: JSON.stringify(params)})) ?? [],
                address_name: (this.selectedSchool.type === 12 ? this.editSnpVal?.nameRu : this.editRegionVal?.nameRu) ||
                    this.selectedSchool.address_name,
                additional_index: ''
            };

            MonitoringSnpApi.put(`/snp/api/v1/registry/update`, editInfo)
                .then(res => {
                    if (res.status === 200) {
                        this.makeToast('info', 'Успешно сохранен', 'Успешно');
                        this.editModalVisible = false;
                        const { katoCode } = this.getCode()
                        this.getSnpData(registryId, katoCode, this.isTemplate);
                        this.getRegistryData(registryId)
                        this.getProgress();
                    }
                })
                .catch(err => this.makeToast('danger', 'Не удалось сохранить изменения', 'Ошибка'))
                .finally(() => {
                    this.loading = false
                });


            this.editModalVisible = false
        },
        saveRegistry() {
            if (this.controlMap.some(item => Boolean(item))){
                [...new Set(this.notificationArray.map(({type, msg, title}) => JSON.stringify({type, msg, title})))].map(JSON.parse).forEach(({type, msg, title}) => this.makeToast(type, msg, title));
                return;
            }

            const myForm = document.getElementById('frm');
            const formData = new FormData(myForm);
            const formValue = {};
            for (const [key, val] of formData.entries()) {
                Object.assign(formValue, { [key]: val })
            }
            const registryId = this.registryVal.id;
            if (registryId === 12 && (!this.createDistrictVal || !this.createSnpVal)){
               this.makeToast('danger', 'Вы не ввели обязательные поля Район, Сельский округ, СНП', 'Ошибка');
               return;
            }
            const body = {
                code: registryId === 12 ? this.createSnpVal.code : this.createRegionVal.code,
                name: registryId === 12 ? formValue['item_field_3'] : formValue['item_field_6'],
                type: registryId,
                year: this.yearVal,
                fields: [],
                address_name: registryId === 12 ? this.createSnpVal.nameRu : this.createRegionVal.nameRu,
                additional_index: ''
            };
            this.formRegistry.forEach((item) => {
                if (this.notVisible.indexOf(item.description) === -1) {
                    let value = item.value;
                    let field = {};

                    if (value){
                        if (!isNaN(item.value) && (item.params?.data_type === 'integer')){
                            value = +item.value;
                        } else if (!isNaN(item.value) && item.params?.data_type === 'float'){
                            value = +((+item.value).toFixed(2));
                        } else if (item.params && item.params.multiple_choice){
                            value = item.value.length ? item.value.map(elem => elem.value).join(', ') : "";
                        } else if (item.isList) {
                            value = item.value?.value;
                        }
                    } else {
                        if (item.params?.data_type === 'integer' || item.params?.data_type === 'float'){
                            value = null;
                        } else if (item.params && item.params.multiple_choice){
                            value = item.value.length ? item.value.map(elem => elem.value).join(', ') : "";
                        } else if (item.isList) {
                            value = null;
                        } else if (item.params?.data_type === 'string'){
                            value = "";
                        }
                    }

                    if (registryId === 12) {
                        if (item.columnNumber === 5) {
                            value = this.createRegionVal.nameRu ?? item.value;
                        } else if (item.columnNumber === 7) {
                            value = this.createSnpVal.nameRu ?? item.value;
                        } else if (item.columnNumber === 6) {
                            value = this.createDistrictVal.nameRu ?? item.value;
                        }

                        field = {
                            value,
                            editable: item.editable,
                            params: JSON.stringify(item.params),
                            head: item.description,
                            column_number: item.columnNumber
                        }
                    } else {
                        if (item.columnNumber === 3){
                            value = this.createRegionVal.nameRu;
                        }
                        field = {
                            value,
                            editable: item.editable,
                            params: JSON.stringify(item.params),
                            head: item.description,
                            column_number: item.columnNumber
                        }
                    }
                    body.fields.push(field);
                }
            });

            MonitoringSnpApi.post(`/snp/api/v1/registry/create`, body)
                .then(res => {
                    if (res.status === 200) {
                        this.makeToast('info', 'Успешно сохранен', 'Успешно');
                        this.createModalVisible = false;
                        const { katoCode } = this.getCode();
                        this.getSnpData(registryId, katoCode, this.isTemplate);
                        this.getRegistryData(registryId);
                        this.getProgress();
                    }
                })
                .catch(err => this.makeToast('danger', 'Не удалось создать', 'Ошибка'))
                .finally(() => {
                    this.loading = false
                });
        },
        loadTemplates() {
            MonitoringSnpApi.get(`/snp/api/v1/registry/templates`)
                .then(res => {
                    if (res.status === 200) {
                        this.templateArr = res.data;
                    }
                })
                .catch(err => console.log(err));
        },
        saveTemplate() {
            if (this.templateName.trim() === '') {
                this.makeToast('danger', 'Вы не ввели наименование шаблона', 'Ошибка');
                return false;
            }
            const code = {};
            const selectedVal = {};
            const selTemplate = this.templateArr.find(template => template.name === this.templateVal);
            if (selTemplate !== null && selTemplate !== undefined) {
                code.katoCode = selTemplate.code;
            }
            if (this.snpVal?.code) {
                code.katoCode = this.snpVal.code;
                selectedVal.id = this.snpVal.id;
                this.katoCodeValue = this.snpVal.code;
            } else if (this.districtVal?.code) {
                code.katoCode = this.districtVal.code;
                selectedVal.id = this.districtVal.id;
                this.katoCodeValue = this.districtVal.code;
            } else if (this.regionVal?.code) {
                code.katoCode = this.regionVal.code;
                selectedVal.id = this.regionVal.id;
                this.katoCodeValue = this.regionVal.code;
            }
            let valueInfo = {};
            const registryId = this.registryVal.id;

            this.currentHeads.forEach((item, index) => {
                const valueInfos = [];
                this.globalArr.forEach((gItem, indexGlobal) => {
                    const v = gItem.fields.find((i) => i.head === item.desc);
                    valueInfo = {
                        value: v.value,
                        address: {
                            region: this.regionVal?.nameRu,
                            district: '',
                            snp: gItem.snp,
                            code: gItem.code
                        },
                        additional_index: gItem.name
                    };
                    valueInfos.push(valueInfo);
                });
                this.sections[index].first = {
                    year: this.yearVal,
                    values: valueInfos,
                    columnNumber: item.columnNumber
                };
            });
            const body = {
                name: this.templateName,
                reportId: registryId,
                sections: this.sections
            };

            if (this.regionVal === null) {
                this.makeToast('danger', 'Вы не выбрали район', 'Ошибка');
                return false;
            }
            MonitoringSnpApi
                .post('/snp/api/v1/report/template/save', body)
                .then(res => {
                    if (res.status === 200) {
                        this.makeToast('info', 'Шаблон успешно сохранен', 'Успешно');
                        this.saveTemplateModalVisible = false;
                        this.loadTemplates();
                    }
                })
                .catch(err => {
                    this.makeToast('danger', err, 'Ошибка!!!');
                });
        },
        canSaveOrAdd(){
            return (this.roles.ADMIN || (+this.selectedRegistry.id === 12 && this.roles.VILLAGE) || (+this.selectedRegistry.id === 13 && this.roles.DISTRICT_DEPARTMENTS))
            && (this.reconciliation.status === SEND_TO_REPROCESS || this.reconciliation.status === IN_PROCESS)
            && ((this.regionVal?.code && this.me.katoCodes.includes(this.regionVal.code.toString())) || (+this.selectedRegistry.id === 12 && this.snpVal?.code && this.me.katoCodes.includes(this.snpVal.code.toString())))
        },
        canSendToApprove(){
            return (this.roles.ADMIN || (+this.selectedRegistry.id === 12 && this.roles.VILLAGE) || (+this.selectedRegistry.id === 13 && this.roles.DISTRICT_DEPARTMENTS))
             && (this.reconciliation.status === SEND_TO_REPROCESS || this.reconciliation.status === IN_PROCESS)
             && ((this.regionVal?.code && this.me.katoCodes.includes(this.regionVal.code.toString())) || (+this.selectedRegistry.id === 12 && this.snpVal?.code && this.me.katoCodes.includes(this.snpVal.code.toString())))
             && this.progress === 100
        },
        canReconcile(){
            return (this.roles.DISTRICT || this.roles.ADMIN || (+this.selectedRegistry.id === 12 && this.roles.DISTRICT_DEPARTMENTS)) &&
                (this.reconciliation.status === SEND_TO_APPROVE || this.reconciliation.status === RECONCILED_BY_DEPARTMENTS) && this.regionVal?.code && this.me.katoCodes.includes(this.regionVal.code.toString())
        },
        canApprove(){
            return (((this.roles.REGION_DEPARTMENTS || this.roles.ADMIN) && this.reconciliation.status === RECONCILED) || ((this.roles.REGION || this.roles.ADMIN) && this.reconciliation.status === APPROVED_BY_DEPARTMENTS)) && this.me.katoCodes.includes(this.regionVal.code.toString())
        },
        canSendToReprocess(){
            return ((((+this.selectedRegistry.id === 12 && this.roles.VILLAGE) || this.roles.DISTRICT || this.roles.ADMIN) && this.reconciliation.status === SEND_TO_APPROVE) ||
                   ((this.roles.REGION_DEPARTMENTS || this.roles.DISTRICT_DEPARTMENTS || this.roles.ADMIN) && this.reconciliation.status === RECONCILED) ||
                   ((this.roles.REGION || this.roles.ADMIN) && this.reconciliation.status === APPROVED_BY_DEPARTMENTS) ||
                   ((this.roles.ADMIN && this.reconciliation.status === APPROVED)))
                   && ((this.regionVal?.code && this.me.katoCodes.includes(this.regionVal.code.toString())) || (+this.selectedRegistry.id === 12 && this.snpVal?.code && this.me.katoCodes.includes(this.snpVal.code.toString())))

        },
        sendToApprove(){
            this.handleAction(SEND_TO_APPROVE)
        },
        reconcile(){
            const status = this.reconciliation.status === SEND_TO_APPROVE ? RECONCILED_BY_DEPARTMENTS : RECONCILED
            this.handleAction(status)
        },
        approve(){
            const status = this.reconciliation.status === RECONCILED ? APPROVED_BY_DEPARTMENTS : APPROVED
            this.handleAction(status)
        },
        sendToReprocess(){
            this.handleAction(SEND_TO_REPROCESS)
        },
        handleAction(status) {
            const data = [{ reconciliationId: this.reconciliation?.id, status }];
            MonitoringSnpApi
                .post('/snp/api/v1/reconciliations', data)
                .then((res) => {
                    this.reconciliation = res.data[0];
                    this.makeToast('success', `Новый статус паспорта - ${this.$t(`modules.monitoringSnp.statuses.${status}`)}`, 'Успешно')
                })
                .catch((err) => {
                    this.makeToast('danger', 'Произошла ошибка на сервере', 'Ошибка')
                });
        },
        getReconciliation(){
            const registryId = this.registryVal?.id;
            const snpVal = this.snpVal?.code || this.me.initialSnp?.katoCode;
            const katoCode = +registryId === 12 ? snpVal : this.regionVal?.code;
            MonitoringSnpApi
                .get(`snp/api/v1/reconciliations/${katoCode}/${registryId}`)
                .then(res => {
                    this.reconciliation = res.data;
                })
        },
        handleChangeValue(item, index, type = 'edit'){
            const params = item.params;
            const values = params?.values?.map(item => item.toLowerCase().trim()) || [];
            const data_type = params?.data_type;
            const fields = type === 'edit' ? this.selectedSchool.fields : this.formRegistry;
            const control = {
                isMoreThan: false,
                isMoreThan100: false,
                isLessThan0: false,
                isEmpty: false,
            }

            if (params?.control?.less_than){
                control.isMoreThan = +item.value > +fields.find(elem => elem.id === params.control.less_than).value;
            }

            fields.forEach((element, index) => {
                if (element.params && element.params.control?.less_than){
                    const foundItem = fields.find(x => x.id === element.params.control?.less_than);
                    this.controlMap[index] = +fields[index].value > +foundItem.value;
                    if (this.controlMap[index]){
                        this.notificationArray.push({type: 'danger', msg: element.params.message, title: 'Ошибка', id: index});
                    }
                }
            })

            if (values.includes('<=') && values.includes('100')){
                control.isMoreThan100 = +item.value > 100;
            }

            if (fields.some(field => field.params && field.params.values?.includes('id_' + item.id))){
                fields.forEach((field, index) => {
                    if (field.params && field.params?.values?.includes('id_' + item.id)){
                        const stack = [];
                        field.params.values.forEach(elem => {
                            if (elem.startsWith('id_')){
                                const foundById = fields.find(element => element.id === +elem.slice(3))
                                stack.push(+foundById.value);
                            } else if (['+', '-', '*', '/'].includes(elem)){
                                const last = stack[stack.length - 1] || 0;
                                const prevLast = stack[stack.length - 2] || 0;
                                stack.pop();
                                stack.pop();
                                if (elem === '+') {
                                stack.push(+last + +prevLast)
                                } else if (elem === '-') {
                                    stack.push(+prevLast - +last)
                                } else if (elem === '*') {
                                    stack.push(+last * +prevLast)
                                } else {
                                    if (+prevLast === +last) {
                                        stack.push(1)
                                    } else {
                                        stack.push(+prevLast / (+last || 1))
                                    }
                                }
                            } else {
                                stack.push(+elem)
                            }
                        })
                        field.value = stack[0];
                        stack.length = 0;
                    }
                })
            }

            if (this.registryVal.id === 13){

                const total = fields.find(item => item.columnNumber === 7);

                const first = fields.find(item => item.columnNumber === 12);
                const second = fields.find(item => item.columnNumber === 14);
                const third = fields.find(item =>  item.columnNumber === 16);

                const firstPercentage = fields.find(item => item.columnNumber === 13);
                const secondPercentage = fields.find(item => item.columnNumber === 15);
                const thirdPercentage = fields.find(item => item.columnNumber === 17);

                if (+first.value + +second.value + +third.value > +total.value){
                    this.controlMap[fields.indexOf(first)] = 1;
                    this.controlMap[fields.indexOf(second)] = 1;
                    this.controlMap[fields.indexOf(third)] = 1;
                    this.notificationArray.push({type: 'danger', msg: first.params.message, title: 'Ошибка', id: fields.indexOf(first)});
                } else {
                    this.controlMap[fields.indexOf(first)] = 0;
                    this.controlMap[fields.indexOf(second)] = 0;
                    this.controlMap[fields.indexOf(third)] = 0;
                    this.notificationArray = this.notificationArray.filter(element => element.id !== fields.indexOf(first));
                }

                fields[fields.indexOf(firstPercentage)].value = +(+first.value / +total.value) * 100;
                fields[fields.indexOf(secondPercentage)].value = +(+second.value / +total.value) * 100;
                fields[fields.indexOf(thirdPercentage)].value = +(+third.value / +total.value) * 100;

                if (type === 'create'){
                    this.refresh();
                }

                return;
            }

            if (item.value === null || item.value === ''){
                control.isEmpty = true;
            }

            if (data_type === 'integer' || data_type === 'float'){
                control.isLessThan0 = item.value < 0;
            }

            if (Object.values(control).some(item => item)){
                if (control.isMoreThan || control.isMoreThan100){
                    this.notificationArray.push({type: 'danger', msg: params.message, title: 'Ошибка', id: index});
                    this.controlMap[index] = 1;
                } else if (control.isLessThan0){
                    this.notificationArray.push({type: 'danger', msg: 'Значение не должно быть меньше 0', title: 'Ошибка', id: index});
                    this.controlMap[index] = 1;
                }
            } else {
                this.controlMap[index] = 0;
            }

            if (type === 'create'){
                this.refresh();
            }

            this.removeNotifications();
        },
        refresh(){
            this.formRegistry.reverse().reverse()
            this.controlMap.reverse().reverse()
        },
        getRegistryForm(){
            this.formRegistry = this.reportHeadersArr.map(item => {
                const params = item.params && JSON.parse(item.params);
                const isList = params && params.values && params.values.every(elem => !elem.startsWith('id_')) && params.values.filter(elem => ['+', '-', '/', '*', '<', '>', '=', '<=', '>='].includes(elem)).length === 0;
                const editable = params && params.editable;

                return {
                    ...item,
                    params,
                    isList,
                    editable
                }
            });
        },
        getProgress(){
            const registryId = this.registryVal?.id;
            const katoCode = +registryId === 12 ? this.snpVal?.code || this.me.initialSnp?.katoCode : this.regionVal?.code;
            MonitoringSnpApi
                .get(`snp/api/v1/reconciliations/progress-total?katoCode=${katoCode}&reportId=${registryId}`)
                .then(res => this.progress = res.data.average_progress)
        },
        removeNotifications(){
            this.controlMap.forEach((item, index) => {
                if (item === 0 && this.notificationArray.find(item => +item.id === +index)){
                    this.notificationArray = this.notificationArray.filter(element => element.id !== index);
                }
            })
        },
        openDeleteModal(){
            this.deleteModalVisible = true;
        },
        deleteRegistry(){
            MonitoringSnpApi
                .delete(`snp/api/v1/registry/delete/${this.selectedSchool.id}`)
                .then(res => {
                    const { katoCode } = this.getCode()
                    this.getSnpData(this.registryVal.id, katoCode, this.isTemplate);
                    this.getRegistryData(this.registryVal.id)
                    this.getProgress();
                    this.makeToast('success', `Объект был удален`, 'Успешно');
                    this.deleteModalVisible = false;
                    this.editModalVisible = false;
                })
                .catch(err => {
                    this.makeToast('danger', `Не удалось удалить объект`, 'Ошибка');
                })
        },
        formatDate (date) {
            return moment(date).format('DD.MM.YYYY HH:mm')
        },
        loadHistory () {
            const params = {
                katoCode: +this.selectedRegistry.id === 12 ? this.snpVal?.code || this.me.katoCodes[this.me.katoCodes.length - 1] : this.regionVal?.code || this.me.katoCodes[this.me.katoCodes.length - 1],
                year: this.yearVal,
                reportId: this.registryVal?.id || 1
            }
            this.historyLoading = true
            MonitoringSnpApi
                .get(`/snp/api/v1/reconciliations/history`, params)
                .then(({ data }) => {
                    this.historyData = data
                    this.historyLoading = false
                })
                .catch(() => this.historyLoading = false)
        },
    }
}
</script>

<style>
/*.actions-tab.in-tab .dropdown-menu {*/
/*    margin-top: -90px;*/
/*}*/
</style>

<style lang="scss" scoped>
.spinner {
    padding-top: calc(100vh / 3.3);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eef1f8;
}

li {
    cursor: pointer;
}

li:hover {
    background-color: #f6f6f6;
}

input.form-control {
    max-width: 100%;
}

.mr-sm-2 {
    font-size: 14px;
    width: 27%;
    justify-content: left;
    color: #6087A0;
}

.col-border {
    border: 1px solid red;
}

.input-group-text {
    background-color: #ffffff;
}

.cursor-point {
    cursor: pointer;
}

.selected-snp {
    margin-left: 20px;
    color: #1C2A3E;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
}

h5.modal-title {
    width: 100%;
}

.filter-container {
    flex-wrap: wrap;
    height: auto;
    gap: 8px;
    padding: 8px 20px;
}

.filter-actions{
    display: flex;
    flex-wrap: nowrap;
    overflow: auto hidden;

    & > button {
        white-space: nowrap;
    }
}

.control-error {
    color: #C0504C !important;
    border: 2px solid #C0504C !important;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.no-wrap {
    flex-wrap: nowrap;
}

.multiselect {
    max-width: 513px;
}
</style>
