<template>
    <div>
        <div class="filter-container">
            <div class="left-content">
                <div class="filter-block">
                    <b-form-group>
                        <multiselect
                            v-model="currentYear"
                            :options="yearsArr"
                            placeholder="Год"
                            :allow-empty="false"
                            :show-labels="false"
                        ></multiselect>
                    </b-form-group>
                </div>
                <div style="padding-left: 7px"></div>
                <div class="filter-block">
                    <b-form-group>
                        <multiselect
                            v-model="regionVal"
                            :options="regions"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Выбрать Регион"
                            label="nameRu"
                            track-by="id"
                            :preselect-first="true"
                            @select="handleFilterClick"
                            @remove="handleFilterClick"
                            >
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.filter(item => item.nameRu !== "Все").length }} выбранные варианты</span></template>
                        </multiselect>

                        <!-- <multiselect
                            v-model="formVal"
                            :options="reportTypes"
                            :multiple="true"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :preserve-search="true"
                            placeholder="Выбрать формы"
                            label="name"
                            track-by="id"
                            :preselect-first="true">
                            <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} выбранные варианты</span></template>
                        </multiselect> -->
                    </b-form-group>
                </div>
                <div style="padding-left: 7px"></div>
                <div class="filter-block selected-list" v-if="regionVal">
                    {{ regionVal.filter(item => item.nameRu !== "Все").map(item => item.nameRu).join(', ') }}
                </div>
                <!-- <div class="filter-block">
                    <b-btn variant="primary" @click="loadZipFile">Применить</b-btn>
                </div> -->
            </div>
        </div>
        <!---------Начало грид таблицы-------------->
        <b-progress variant="success" v-show="bar<100" height="10px" :value="bar" striped animated></b-progress>
        <div class="table-container">
            <div class="b-table-sticky-header table-responsive-true">
                <table class="table b-table table-bordered b-table-no-border-collapse">
                    <thead>
                        <tr>
                            <td style="width: 40px"></td>
                            <td>Показатель</td>
                            <td style="width: 40px"></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td>
                              <a class="table-btn-link" style="font-size: 1rem" @click="loadVillagePassport">
                                Паспорт села
                              </a>
                            </td>
                            <td><img :src="require('./icons/3-vertical-dots.svg')" height="17px" alt=""></td>
                        </tr>
                        <tr :key="'itemIndx'">
                            <td></td>
                            <td><a variant="link" class="table-btn-link" @click="loadFormFile(0, 'СВОД')">СВОД</a></td>
                            <td><img :src="require('./icons/3-vertical-dots.svg')" height="17px" alt=""></td>
                        </tr>
                        <template>
                           <template >
                                <template v-for="(item, index) of this.reportTypes">
                                    <tr :key="'itemIndx' + index">
                                        <td></td>
                                        <td><a variant="link" class="table-btn-link" @click="loadFormFile(item.id, item.name)">{{item.name}}</a></td>
                                        <td><img :src="require('./icons/3-vertical-dots.svg')" height="17px" alt=""></td>
                                    </tr>
                                </template>
                            </template>

                        </template>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { Ax } from '@/utils';
import { MonitoringSnpApi } from "@/modules/monitoring-snp/api/MonitoringSnpApi";

export default {
    name: 'monitoring-form',
    props: ['reportTypes', 'regions'],
    data() {
        return {
            bar: 0,
            tableFields: [
                {
                    key: 'action',
                    label: ''
                },
                {
                    key: 'name',
                    label: 'Показатель'
                }
            ],
            globalArr: [],
            yearsArr: [],
            regionVal: null,
            currentYear: null,
            formVal: null,
            lastClickedOption: null,
            hostName: '',
        }
    },
    mounted() {
        const currentYear = new Date().getFullYear();
        this.listOfYears(currentYear);
        this.hostName = location.hostname.split('.')[0]
    },
    methods: {
        sendCurrentYear() {
            return this.currentYear;
        },
        listOfYears(currentYear) {
            this.yearsArr = [];
            if (currentYear !== null) {
                for (let i = 2014; i < (currentYear); i++) {
                    this.yearsArr.push(i);
                }
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') { return; }
            if (!item.visible) { return 'is-hidden'; }
        },
        loadZipFile() {
            let url = '/snp/api/v1/report/file/multiform?';
            this.formVal.forEach((form) => {
                url += `forms=${form.id}&`;
            });
            url += `year=${this.currentYear}`;
            Ax(
                {
                    url,
                    method: 'GET',
                    data: null,
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.token}`
                    }
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `forms.zip`);
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    console.log('err', error);
                }
            );
        },
        filterIsValid() {
            const { currentYear, regionVal } = this;
            if (currentYear === null || currentYear === undefined) {
                alert('Вы не выбрали год');
                return false;
            }
            if (!regionVal || (regionVal && regionVal.length === 0)) {
                alert('Вы не выбрали регион');
                return false;
            }

            return true;
        },
        loadVillagePassport() {
            if (this.filterIsValid()) {
                let params = `?year=${this.currentYear}`;
                if (this.regionVal.map(item => item.nameRu).includes('Все')){
                    params += `&code=${this.regionVal.find(item => item.nameRu === 'Все').code}`;
                } else {
                    this.regionVal.forEach((form) => {
                        params += `&code=${form.code}`;
                    });
                }

                MonitoringSnpApi.downloadFile(
                    `/snp/api/v1/report/file/settlement/batch${params}`,
                    {},
                    `village-passport-${this.currentYear}.zip`
                );
            }
        },
        loadFormFile(formId, fileName) {
            if (this.filterIsValid()) {
                this.loadFormWithRegion(formId, fileName);
            }
        },
        loadFormWithRegion(formId, fileName){
            let url = '/snp/api/v1/report/file/download?';
            if (this.regionVal.map(item => item.nameRu).includes('Все')){
                    url += `&codes=${this.regionVal.find(item => item.nameRu === 'Все').code}`;
                } else {
                    this.regionVal.forEach((form) => {
                    url += `codes=${form.code}&`;
                });
            }

            Ax(
                    {
                        url: `${url}&reportId=${formId}&year=${this.currentYear}`,
                        method: 'GET',
                        data: null,
                        responseType: 'blob',
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.token}`
                        }
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${fileName}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        console.log('err', error);
                    }
            )
        },
        handleFilterClick(option){
            this.lastClickedOption = option.nameRu;
        }
    },
    watch: {
        regionVal(newArr, oldArr){
            const newIncludesAll = newArr?.map(item => item.nameRu).includes('Все');
            const oldIncludesAll = oldArr?.map(item => item.nameRu).includes('Все');

            if (this.lastClickedOption === 'Все'){
                if (newIncludesAll){
                    this.regionVal = this.regions;
                } else if (oldIncludesAll) {
                    this.regionVal = [];
                }
            } else if (newArr?.length !== this.regions.length && newIncludesAll){
                this.regionVal = this.regionVal.filter(item => item.nameRu !== "Все")
            } else if (newArr?.length === this.regions.length - 1 && !newIncludesAll){
                this.regionVal = this.regions;
            }
        }
    }
};
</script>
<style lang="scss">
.table-container .table-btn-link {
    font-weight: bold;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: none;
    cursor: pointer;
}

.left-content {
    display: flex;
    gap: 8px;
}

.selected-list {
    color: #1C2A3E;
    font-weight: 700;
    font-size: 14px;
    max-width: 68%;
}
</style>
